@import "../../styles/theme.scss";

.tableToolbar {
  display: flex;
  flex-direction: column;
  background-color: $color-blue50;
  gap: $space-XL;
  padding: $space-M $space-XL;
  border-radius: $space-S;

  @include breakpoint-min($breakpoint-S) {
    flex-direction: row;
    justify-content: space-between;
  }
}
