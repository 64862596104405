@import "../../styles/theme";

.header {
  margin-bottom: $space-L;
  margin-left: $space-S;

  @include breakpoint-min($breakpoint-S) {
    margin-left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.item {
  display: flex;
  align-items: center;
  gap: $space-S;
  padding: $space-M;

  &:hover {
    background-color: $color-gray50;
  }
}

.clearButton {
  margin-top: $space-L;
  margin-left: $space-S;

  @include breakpoint-min($breakpoint-S) {
    margin: 0;
  }
}
