@import "@wingspanhq/fe-component-library/dist/theme.scss";

.layout {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  overflow-y: auto;
  min-height: 100vh;
  background-color: $color-gray50;
}

.header {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: pxToRem(1) solid $color-gray200;
  background-color: $color-white;
}

.closeIcon {
  position: absolute;
  top: $space-L;
  right: $space-XL;
}

.content {
  width: 100%;
  max-width: pxToRem(760);
  margin: 0 auto;
  padding: $space-XL;

  &.twoColumn {
    max-width: pxToRem(956);
  }

  &.threeColumn {
    max-width: pxToRem(1056);
  }

  @include breakpoint-min($breakpoint-M) {
    padding: $space-2XL 0;
  }
}

.navigation {
  display: flex;
  flex-direction: column;
  gap: $space-L;

  .navigationItem.navigationItemActive {
    border-color: $color-gray400;
  }
}

.navigationLevel2 {
  display: flex;
  flex-direction: column;
  gap: $space-2XL;
  margin-top: $space-2XL;
  padding-left: $space-M;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: pxToRem(23);
    width: pxToRem(2);
    background-color: #d1d5db;
  }

  .navigationItem {
    display: flex;
    align-items: center;
    gap: $space-M;
  }
}
