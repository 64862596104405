@import "@wingspanhq/fe-component-library/dist/theme.scss";

.helpCenterWrapper {
  position: relative;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 999;
}

.helpCenter {
  position: absolute;
  z-index: 1000;
  background: $color-white;
  border: pxToRem(1) solid $color-gray200;
  box-shadow: $shadow-M;
  border-radius: pxToRem(1);
}

.helpCenterItem {
  flex-wrap: nowrap;
  width: 100%;
  height: pxToRem(40);
  padding: 0 $space-2XL 0 $space-XL;

  &:hover {
    background-color: $color-gray50;
  }

  * {
    white-space: nowrap;
  }
}

.headaway {
  padding: 0 $space-2XL 0 $space-XL;
  > p {
    margin-left: $space-M;
    font-size: $font-size-14;
  }
  > p + span {
    top: $space-S !important;
    left: pxToRem(36) !important;
    height: $space-S !important;
    width: $space-S !important;
    border-radius: $space-S !important;
  }
}

.fixedHelpCenterWrapper {
  position: fixed;
  right: $space-5XL;
  bottom: $space-5XL;
  width: 100%;
  z-index: 1000;
}

.fixedHelpCenter {
  position: absolute;
  right: 0;
  bottom: 0;
  width: pxToRem(44);
  height: pxToRem(44);
  overflow: hidden;

  background: #f8f9fc;
  background-image: url("../../assets/images/help.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: pxToRem(10) pxToRem(15);
  box-shadow: $shadow-L;
  border-radius: pxToRem(22);
}
