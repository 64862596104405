@import '../../../styles/theme';

.container {
  flex: 1;
  width: 100%;
}

.wrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999999999;

  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.iconClose {
  position: absolute;
  top: $space-XL;
  right: $space-XL;
  color: $color-gray600;
  z-index: 1;
}

.iconBack {
  position: absolute;
  top: $space-XL;
  left: $space-XL;
  color: $color-gray600;
  z-index: 1;
}
