@import "@wingspanhq/fe-component-library/dist/theme.scss";

.banner {
  background-color: #fff2e8;
}

.button {
  border-radius: pxToRem(6);
}

.phoneNumberField {
  flex: 1;
}

.input {
  background-color: transparent;
  border-radius: pxToRem(4);
  border: pxToRem(1) solid $color-gray600;
}

.contryCodeInput {
  width: $space-5XL;
  margin-right: $space-M;
}
