@import "../../styles/theme";

.navigation {
  display: flex;
  flex-direction: column;
  gap: $space-S;
}

.navigationButtons {
  display: flex;
  flex-direction: column;
  gap: $space-S;
}

.navigationQuickFilters {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $space-S;
}

.navigationInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $space-S;
}

@include breakpoint-min($breakpoint-S) {
  .navigation {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .navigationButtons {
    flex-direction: row;
  }

  .navigationQuickFilters {
    flex: 1;
    justify-content: flex-start;
  }
}

.activeFilters {
  display: flex;
  flex-wrap: wrap;
  gap: $space-S;
}

.sidebarBody {
  width: 100%;
  height: 100%;
  justify-items: stretch;
}

.sidebarFilters {
  width: 100%;
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
}

.sidebarFooter {
  border-top: pxToRem(1) solid $color-gray200;
  width: 100%;
  padding: $space-M;
}

.sidebarBody .quickFilters {
  display: flex;
  flex-direction: column;
  gap: $space-M;
  margin: $space-XL;
}

.sidebarBody .activeFilters {
  justify-content: center;
  flex-wrap: wrap;
}

.search {
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
  width: 100%;
  padding: $space-M $space-XL;
  border-bottom: pxToRem(1) solid $color-gray200;
}

.searchInput {
  border: none;
  outline: none;
  flex: 1;
  width: 100%;

  font-family: $font-centra;
  font-size: $font-size-12;
  font-style: normal;
  font-weight: $font-weight-book;
  line-height: $line-height-16;
}

.filter {
  width: 100%;
  border-top: pxToRem(1) solid $color-gray200;
  padding: 0 $space-XL;

  &:first-child {
    border-top: none;
  }
}

.filterHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: $space-S;
  padding: $space-M 0;
}

.filterTitle {
  flex: 1;
}

.filterField {
  padding-bottom: $space-S;
}

.filterInput {
  margin-bottom: $space-M;
}

.optionItem {
  padding: $space-M;
}

.customDateRange {
  display: flex;
  align-items: center;
  padding: $space-S $space-M;
  gap: $space-M;
}

.amountRange {
  display: flex;
  align-items: center;
  padding: $space-S $space-M;
  gap: $space-M;
}

.optionHelpText-checkbox {
  margin: pxToRem(6) 0 0 pxToRem(24);
}

.optionHelpText-switch {
  margin: pxToRem(6) 0 0 pxToRem(34);
}

.optionHelpText-radio {
  margin: pxToRem(6) 0 0 pxToRem(24);
}
