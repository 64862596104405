@import "@wingspanhq/fe-component-library/dist/theme";

.searchInput {
  margin-left: $space-S;
}

.search {
  border: solid pxToRem(1) $color-gray200;
  border-radius: pxToRem(4);
  background-color: $color-white;

  i {
    color: $color-gray500;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
  background-color: $color-white;
}

.list {
  overflow-y: auto;
  max-height: pxToRem(420);
}
