@import "@wingspanhq/fe-component-library/dist/theme.scss";

.checkbox {
  width: 100%;
}

@include breakpoint-min($breakpoint-S) {
  .checkbox {
    width: 50%;
  }
}
