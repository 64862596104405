@import "@wingspanhq/fe-component-library/dist/theme.scss";

.container {
  background: white;
  border-radius: pxToRem(6);

  .header {
    padding: $space-XL;
    border-bottom: pxToRem(1) solid $color-gray50;
  }

  .main {
    padding: $space-XL;
  }
}
