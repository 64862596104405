@import "@wingspanhq/fe-component-library/dist/theme.scss";

.container {
  padding: 0;
}

.section {
  padding: $space-XL 0 $space-2XL 0;
  border-bottom: pxToRem(1) solid $color-gray200;

  .error {
    color: $color-garnet;
  }
}

.cardLogo {
  width: $space-3XL;
  margin-right: $space-M;
}

.reactivateLink {
  color: $color-red400;

  &:hover {
    color: $color-red500;
  }
}

.creditCardForm {
  .expiration {
    margin: $space-M 0;
  }
}
