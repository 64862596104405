@import "@wingspanhq/fe-component-library/dist/theme.scss";

.logo {
  display: block;
  object-fit: contain;
  max-width: pxToRem(166);
  max-height: pxToRem(52);

  @include breakpoint-min($breakpoint-S) {
    max-width: pxToRem(180);
    max-height: pxToRem(40);
  }

  &.whiteLabeled {
    max-width: pxToRem(180);
    max-height: pxToRem(40);

    @include breakpoint-min($breakpoint-S) {
      max-width: pxToRem(200);
      max-height: pxToRem(44);
    }
  }

  &.small {
    max-width: pxToRem(152);
    max-height: pxToRem(30);
  }
}
