@import "@wingspanhq/fe-component-library/dist/theme.scss";

.tooltip {
  display: inline-block;
}

.popper {
  max-width: pxToRem(280);
  border-radius: pxToRem(6);
  padding: $space-XL;
  background-color: $color-gray700;
  color: $color-gray50;
  z-index: 10000;
  box-shadow: $shadow-S;

  .arrow {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-width: $space-S;
    border-style: solid;
  }

  &[x-placement="top"] {
    .arrow {
      bottom: -$space-L;
      border-top-color: $color-black;
    }
  }

  &[x-placement="right"] {
    .arrow {
      left: -$space-L;
      border-right-color: $color-black;
    }
  }

  &[x-placement="bottom"] {
    .arrow {
      top: -$space-L;
      border-bottom-color: $color-black;
    }
  }

  &[x-placement="left"] {
    .arrow {
      right: -$space-L;
      border-left-color: $color-black;
    }
  }
}
