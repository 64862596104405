@import "../../styles/theme.scss";

.card {
  border-color: $color-gray200;
  border-style: solid;
  border-width: pxToRem(1);
  border-radius: pxToRem(8);
  padding: $space-M 0;
  background-color: $color-white;

  &.fullHeight {
    height: 100%;
    display: flex;
    flex-direction: column;

    .spacer {
      flex: 1;
    }
  }

  &.clickable:hover {
    box-shadow: $shadow-M;
  }

  &.clickable.active {
    border-color: $color-blue400;
    background-color: $color-blue50;
  }

  &.clickable:focus {
    box-shadow: 0 0 0 pxToRem(4) $color-blue100;
  }
}

.header {
  padding: $space-S $space-XL;
}

.content {
  padding: $space-M $space-XL;
}

.header + .content {
  padding-top: $space-XS;
}

.actions {
  padding: 0 $space-XL;
}

.card.divider {
  .header {
    border-bottom: pxToRem(1) solid $color-gray200;
    padding-bottom: $space-M;
  }

  .content {
    padding-top: $space-L;
    padding-bottom: $space-L;
  }

  .actions {
    padding-top: $space-M;
  }

  .content ~ .actions {
    border-top: pxToRem(1) solid $color-gray200;
  }
}
