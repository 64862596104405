@import "../../../../node_modules/@wingspanhq/fe-component-library/dist/theme";

.bodyImage {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
}

.bodyImageComplete {
  max-height: pxToRem(320);
  background-image: url("./images/complete.svg");
  background-position: calc(50% + 16px) center;
}

.bodyImageNoResults {
  max-height: pxToRem(200);
  background-image: url("./images/noResults.svg");
  background-position: center;
}
