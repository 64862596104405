@import "@wingspanhq/fe-component-library/dist/theme.scss";

.progressContainer {
  align-self: normal;
  height: $space-XS;
  border-radius: pxToRem(2);
  background-color: $color-blue100;

  .progress {
    width: 0;
    transition-property: width;
    transition-timing-function: ease-in-out;
    background-color: $color-blue400;
    border-radius: pxToRem(2);
    height: $space-XS;
  }
}
