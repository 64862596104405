@import "@wingspanhq/fe-component-library/dist/theme.scss";

.stepper {
  padding: $space-L;
}

.stepperContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    height: pxToRem(2);
    background-color: #d1d5db;
  }
}

.stepTitles {
  align-content: center;
}
