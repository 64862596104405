@import "@wingspanhq/fe-component-library/dist/theme.scss";

.steps {
  display: flex;
  justify-content: space-between;
}

.step {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: $space-S;

  .title {
    max-width: pxToRem(70);
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    height: pxToRem(1.5);
    bottom: $space-M;
    left: calc(50% + pxToRem(18));
    right: calc(-50% + pxToRem(18));
    background-color: $color-gray400;
  }

  &:first-child {
    align-items: flex-start;

    .point {
      margin-left: pxToRem(23);
    }

    &::after {
      left: pxToRem(55);
    }
  }

  &:last-child {
    align-items: flex-end;

    .point {
      margin-right: pxToRem(23);
    }

    &::after {
      left: calc(-50% + pxToRem(18));
      right: pxToRem(55);
    }
  }
}
