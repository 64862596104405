@import '../../../styles/theme.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}

.itemListWrapper {
  position: relative;
  width: 100%;
}

.itemList {
  position: absolute;
  width: 100%;
  display: flex;
  max-width: 100%;
}

.carousel {
  justify-content: flex-start;
}

.itemWrapper {
  display: flex;
  margin: auto;
  transition: transform 0.5s;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: opacity 1s;
  text-align: center;

  * {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }
}

.inactive {
  opacity: 0;
}

.indicator {
  width: $space-M;
  height: $space-M;
  border-style: solid;
  border-radius: 50%;
  border-color: $color-gray500;
  position: relative;
  margin-left: pxToRem(6);
  margin-right: pxToRem(6);
  margin-top: pxToRem(14);
  cursor: pointer;

  i {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: pxToRem(6);
    height: pxToRem(6);
    background-color: $color-gray500;
    border-radius: 50%;
  }
}
