@import "@wingspanhq/fe-component-library/dist/theme.scss";

.container {
  width: 100%;

  .header {
    width: 100%;
    border-bottom: pxToRem(2) solid $color-gray200;
  }

  .section {
    width: 100%;
    &:not(:last-child) {
      border-bottom: pxToRem(2) solid $color-gray200;
    }

    .row {
      width: 100%;
    }
  }

  .viewAllBtn {
    i {
      width: $space-M;
      height: $space-M;

      margin-left: $space-XS;
    }
  }
}

.selectWrapper {
  max-width: pxToRem(98);
  label {
    background-color: transparent;
    border: none;
    &:focus-within,
    &:focus {
      outline: none;
      border: none;
    }
    > input {
      text-align: right;
    }
  }
}

.panel {
}
