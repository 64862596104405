@import "../../styles/theme.scss";

.wrapper {
  position: relative;
  display: flex;
  justify-content: stretch;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;
  width: 100%;
  padding: $space-S 0;
}

.title {
  min-width: auto;
  width: auto;
}

.pill {
  margin-left: $space-S;
}

.tooltipIcon {
  margin-left: $space-S;
}

.hasBorder {
  border-bottom: pxToRem(1) solid $color-gray300;
}

.space {
  flex: 1;
}

.button {
  padding-top: 0;
  padding-bottom: 0;
}
