@import "@wingspanhq/fe-component-library/dist/theme.scss";

.helpLink {
  font-size: $font-size-12 !important;
}

.externalLink {
  color: $color-gray500;
}

.splitter {
  height: pxToRem(24);
  width: pxToRem(1);
  background-color: $color-gray200;
}