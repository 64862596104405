@import "@wingspanhq/fe-component-library/dist/theme.scss";

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $space-2XL;
  height: $space-2XL;
  border-radius: $space-L;
  overflow: hidden;
  background-color: $color-gray200;
}

.cardGridItem {
  display: flex;
}

.card {
  flex: 1;
}
