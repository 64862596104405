@import "@wingspanhq/fe-component-library/dist/theme.scss";

.logo {
  width: pxToRem(28);
  height: pxToRem(18);

  > * {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
}
