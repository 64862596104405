@import "../../../styles/theme.scss";

.base {
  display: block;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.inline {
  display: inline;
}

.tabularNums {
  font-variant-numeric: tabular-nums;
}

.singleLine {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.strike {
  text-decoration: line-through;
}

.underline {
  text-decoration: underline;
}

.strike.underline {
  text-decoration: line-through underline;
}

.italic {
  font-style: italic;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.Display1 {
  font-family: $font-centra;
  font-size: $font-size-60;
  line-height: $line-height-64;
  font-weight: $font-weight-medium;
}

.Display2 {
  font-family: $font-centra;
  font-size: $font-size-48;
  line-height: $line-height-60;
  font-weight: $font-weight-medium;
}

.Heading1 {
  font-family: $font-centra;
  font-size: $font-size-36;
  line-height: $line-height-40;
  font-weight: $font-weight-medium;
}

.Heading2 {
  font-family: $font-centra;
  font-size: $font-size-30;
  line-height: $line-height-36;
  font-weight: $font-weight-medium;
}

.Heading3 {
  font-family: $font-centra;
  font-size: $font-size-24;
  line-height: $line-height-32;
  font-weight: $font-weight-medium;
}

.Heading4 {
  font-family: $font-centra;
  font-size: $font-size-20;
  line-height: $line-height-28;
  font-weight: $font-weight-medium;
}

.Heading5 {
  font-family: $font-centra;
  font-size: $font-size-18;
  line-height: $line-height-28;
  font-weight: $font-weight-medium;
}

.Paragraph {
  font-family: $font-centra;
  font-size: $font-size-16;
  line-height: $line-height-24;
  font-weight: $font-weight-book;
}

.ParagraphSm {
  font-family: $font-centra;
  font-size: $font-size-14;
  line-height: $line-height-20;
  font-weight: $font-weight-book;
}

.ParagraphXs {
  font-family: $font-centra;
  font-size: $font-size-12;
  line-height: $line-height-16;
  font-weight: $font-weight-book;
}

.Caption {
  font-family: $font-centra;
  font-size: $font-size-10;
  line-height: 1.5;
  text-transform: uppercase;
  font-weight: $font-weight-book;
}

.weight-thin {
  font-weight: $font-weight-thin;
}

.weight-light {
  font-weight: $font-weight-light;
}

.weight-book {
  font-weight: $font-weight-book;
}

.weight-medium {
  font-weight: $font-weight-medium;
}

.weight-bold {
  font-weight: $font-weight-bold;
}
