@import "@wingspanhq/fe-component-library/dist/theme.scss";

.header {
  .headerInner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $space-XL 0;

    @include breakpoint-min($breakpoint-S) {
      padding: $space-2XL 0;
    }
  }

  .backButton {
    position: absolute;
    left: 0;
  }
}

.partnerLogo {
  img {
    max-width: pxToRem(129);
    max-height: pxToRem(48);

    @include breakpoint-min($breakpoint-S) {
      max-width: pxToRem(234);
      max-height: pxToRem(72);
    }
  }
}

.banner {
  background-color: $color-gray50;

  .bannerInner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: pxToRem(36);

    @include breakpoint-min($breakpoint-S) {
      height: $space-3XL;
    }
  }
}

.container {
  &:not(.containerWide) {
    .content {
      margin: 0 auto;
      max-width: pxToRem(380);
    }
  }
}
