@import '../../../styles/theme.scss';

.progressWrapper {
  display: block;
  width: 100%;
}

.progressBody {
  display: block;
  width: 100%;
  background: #e1f3ff;
  border-radius: $space-M;
  height: $space-XS;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.progressLine {
  display: block;
  padding: 0;
  margin: 0;
  height: $space-XS;
  background: #4cb0f3;
  border-radius: $space-M;
  transition: width linear 0.25s;

  &.noAnimation {
    transition: none;
  }
}
