@import "@wingspanhq/fe-component-library/dist/theme.scss";

.switchAccounts {
  width: 100%;
  margin-bottom: $space-XL;
}

.tooltip {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: pxToRem(40);
  cursor: pointer;
  margin-bottom: $space-XL;

  &:hover {
    background-color: $color-gray50;
  }
}

.newTooltip {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: pxToRem(40);
  cursor: pointer;
  margin-bottom: $space-XL;

  button {
    height: pxToRem(40);
    width: pxToRem(40);
  }

  i {
    width: $space-M !important;
    height: $space-M !important;
    min-width: $space-M !important;
    min-height: $space-M !important;
    padding: 0;
    margin: 0;
  }
}

.dropdownWrapper {
  width: 100%;
}

.trigger {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  flex-wrap: nowrap;
  position: relative;
  padding: 0 $space-M;
  width: 100%;
  height: pxToRem(40);

  border: pxToRem(1) solid $color-gray200;
  border-radius: pxToRem(2);

  .ellipsis {
    max-width: pxToRem(120);
  }
}

.newTrigger {
  cursor: pointer;
  position: relative;
  width: 100%;
  height: pxToRem(40);

  i {
    width: $space-M !important;
    height: $space-M !important;
    min-width: $space-M !important;
    min-height: $space-M !important;
  }

  button {
    height: pxToRem(40);
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdownContent {
  cursor: auto;
  display: block;
  position: absolute;
  z-index: 1;
  top: 110%;
  width: 100%;
  left: 0;
  background-color: white;
  padding: $space-M 0;
  border: pxToRem(1) solid $color-gray200;
  box-shadow: $shadow-M;
  border-radius: pxToRem(2);
}

.mainDropdownItem {
  cursor: auto;
}

.dropdownItem {
  cursor: pointer;
  height: pxToRem(40);
  width: 100%;

  &:hover {
    background-color: $color-gray50;
  }
}
