@import "../../../styles/theme.scss";

.base {
  display: flex;
  border-width: pxToRem(1);
  border-style: solid;

  .icon {
    // Align icon with first line
    margin-top: pxToRem(1);
  }

  .text {
    font-family: $font-centra;
    font-weight: $font-weight-book;
    font-size: $font-size-16;
    line-height: $line-height-20;
    flex: 1;
  }

  .closeButton {
    position: absolute;
    top: $space-M;
    right: $space-M;
  }
}

.size {
  &-Medium {
    padding: $space-M;
  }
  &-Large {
    padding: $space-XL;

    .text {
      font-weight: $font-weight-medium;
    }

    .closeButton {
      top: $space-XL;
      right: $space-XL;
    }
  }
}

.kind {
  &-Regular {
    border-color: $color-blue400;
    background-color: $color-blue50;

    .icon {
      color: $color-blue400;
    }

    .title {
      color: $color-blue400;
    }
  }

  &-Warning {
    border-color: $color-amber300;
    background-color: $color-amber50;

    .icon {
      color: $color-amber400;
    }

    .title {
      color: $color-amber400;
    }
  }

  &-Error {
    border-color: $color-red500;
    background-color: $color-red50;

    .icon {
      color: $color-red500;
    }

    .title {
      color: $color-red500;
    }
  }

  &-Success {
    border-color: $color-green400;
    background-color: $color-green50;

    .icon {
      color: $color-green500;
    }

    .title {
      color: $color-green500;
    }
  }

  &-Info {
    border-color: $color-gray400;
    background-color: $color-gray100;

    .icon {
      color: $color-gray400;
    }

    .title {
      color: $color-gray400;
    }
  }
}

.noBorder {
  border-color: transparent;
}

.centered {
  justify-content: center;
}
