@import "@wingspanhq/fe-component-library/dist/theme.scss";

.selected {
  background-color: lighten($color-blue50, 5%);
  border-color: $color-blue300;
}

.container {
  background-color: $color-gray50;
}

.content {
  flex: 1;
}
