@import "@wingspanhq/fe-component-library/dist/theme.scss";

.totalCostFieldContainer {
  min-height: pxToRem(44);
}

.totalCostField {
  flex: 1;
}

.rateField {
  max-width: pxToRem(150);
}

.quickbooksField {
}

.costPerUnitField {
  max-width: pxToRem(120);
}

.quantityField {
  max-width: pxToRem(56);
}

.customUnitField {
  max-width: pxToRem(50);
}

.discountTypeField {
  max-width: pxToRem(150);
}

.fullWidth {
  width: 100%;
}

.convertedValue {
  min-width: 45%;
}
