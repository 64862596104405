@import "../../styles/theme";

@keyframes fromBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.snackbarContainer {
  position: fixed;
  z-index: 1000;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 0;
}

.snackbarList {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 0;
  bottom: 0;
  width: 100%;
  align-items: center;
  padding-right: $space-XS;

  @include breakpoint-min($breakpoint-S) {
    width: auto;
    align-items: flex-end;
  }
}

.snackbar {
  flex-wrap: nowrap;
  align-items: stretch;
  background-color: $color-gray50;
  overflow: hidden;
  border-radius: pxToRem(4);
  opacity: 0;
  width: pxToRem(400);
  max-width: calc(100% - 8px);
}

.snackbarMounted {
  animation: fromBottom 0.25s linear forwards;
}

.textWrapper, .iconWrapper, .actionWrapper, .closeIconWrapper {
  display: flex;
  align-items: center;
}

.textWrapper {
  flex: 1;
}

.text {
  line-height: 1;
  padding: $space-L $space-XL;
}

.actionWrapper {
  padding: $space-S $space-L;
}

.iconWrapper, .closeIconWrapper {
  padding: $space-L;
}

.actionWrapper,
.actionWrapper + .closeIconWrapper {
  border-left: pxToRem(1) solid $color-gray200;
}

.dark {
  background-color: $color-gray700;

  .iconWrapper {
    background-color: $color-gray600;
  }

  .closeIcon,
  .text {
    color: $color-white;
  }
}


.light {
  background-color: $color-gray50;

  .iconWrapper {
    background-color: $color-gray200;
  }

  .closeIcon,
  .text {
    color: $color-gray600;
  }
}
