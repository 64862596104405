@import "@wingspanhq/fe-component-library/dist/theme.scss";

.settingItem {
  padding: $space-XL 0 $space-2XL 0;
  display: flex;
  justify-content: space-between;
  border-bottom: pxToRem(1) solid $color-gray200;

  .infoContainer {
    display: flex;
    flex: 2;
    flex-direction: column;
    justify-content: space-between;

    .label {
      color: $color-gray500;
    }

    .value {
      color: $color-gray700;
    }
  }
  .actionContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    white-space: nowrap;
    margin-left: $space-3XL;
  }

  .linkValue {
    text-align: left;
  }
}

.formModalHeader {
  display: flex;
  align-items: center;

  .backIcon {
    color: $color-gray600;
  }
  border-bottom: pxToRem(1) solid $color-gray200;
}
