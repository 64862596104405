@import "../../styles/theme.scss";

.infobox {
  padding: $space-M $space-L;
  color: $color-gray600;
  font-family: $font-centra;
  font-weight: $font-weight-book;

  .title {
    font-weight: $font-weight-medium;
  }

  p,
  ul,
  ol {
    margin: 0;
    padding: 0;
    margin-bottom: $space-L;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul,
  ol {
    padding-left: $space-L;

    li {
      margin-bottom: $space-XS;

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul,
    ol {
      margin-top: $space-XS;
      color: $color-gray500;
    }

    ul {
      list-style: disc;
    }
  }

  a {
    font-weight: $font-weight-medium;
    color: $color-blue400;
    text-decoration: none;
  }

  b {
    font-weight: $font-weight-medium;
  }
}

.theme-info {
  background-color: $color-gray50;
}

.theme-warning {
  background-color: $color-red50;

  .title {
    color: $color-red500;
  }
}

.transparent {
  padding: 0;
  background-color: transparent;
}

.size-S {
  font-size: $font-size-12;
  line-height: $line-height-16;
}

.size-M {
  font-size: $font-size-14;
  line-height: $line-height-20;
}
