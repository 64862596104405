@import "../../../styles/theme.scss";

.list {
  display: flex;
  justify-content: space-between;
  padding-bottom: pxToRem(14);
  border-bottom: pxToRem(1) solid $color-gray200;

  @include breakpoint-min($breakpoint-M) {
    display: block;
    padding-bottom: 0;
    border-bottom: 0;
  }
}

.listItem {
  position: relative;
  font-family: $font-centra;
  font-size: pxToRem(10);
  line-height: pxToRem(15);
  font-weight: 500;
  color: $color-gray200;
  text-align: center;

  &::after {
    content: "";
    display: block;
    position: absolute;
    height: $space-M;
    width: $space-M;
    border-radius: 50%;
    background-color: $color-gray200;
    bottom: -(pxToRem(20));
    left: 50%;
    transform: translateX(-50%);
  }

  &:first-child {
    text-align: left;

    &::after {
      left: 0;
      transform: none;
    }
  }

  &:last-child {
    text-align: right;

    &::after {
      left: auto;
      right: 0;
      transform: none;
    }
  }

  @include breakpoint-min($breakpoint-M) {
    display: flex;
    align-items: center;
    border-left: pxToRem(2);
    border-left-color: $color-gray100;
    border-left-style: solid;
    padding-left: $space-2XL;
    height: pxToRem(36);

    font-family: $font-centra;
    font-size: $font-size-16;
    line-height: $line-height-20;
    font-weight: 500;
    color: $color-gray600;
    text-align: left;

    &::after {
      content: none;
    }

    &:last-child {
      text-align: left;
    }
  }
}

.active {
  color: $color-gray600;

  &::after {
    background-color: $color-gray600;
  }

  @include breakpoint-min($breakpoint-M) {
    border-left-color: $color-blue500;

    & ~ * {
      .listItemText {
        color: $color-gray200;
      }
    }
  }
}
