@import "../../../styles/theme";

.inputContainer {
  display: flex;
  align-items: center;

  > input {
    ::placeholder {
      opacity: 1;
      color: $color-gray700;
    }
  }
  width: 100%;
  box-sizing: border-box;
  outline: none;
  padding: $space-M;
  background-color: $color-gray50;
  border: pxToRem(1) solid transparent;
  border-bottom: pxToRem(1) solid $color-gray500;

  &:focus-within {
    border: pxToRem(1) solid $color-blue400;
  }

  &.error {
    border: pxToRem(1) solid $color-red400;
    background-color: $color-red50;
  }
}

.input {
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 0;
  font-family: $font-centra;
  font-size: $font-size-16;
  line-height: $line-height-20;
  font-weight: normal;
  color: $color-gray700;
  resize: none;
  min-height: pxToRem(60);

  &:focus {
    border: none;
    outline: none;
  }
}

.input:empty:not(:focus):before {
  content: attr(placeholder);
  font-family: $font-centra;
  font-size: $font-size-16;
  font-weight: normal;
  line-height: $line-height-20;
  color: $color-gray600;
}
