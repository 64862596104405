@import '../../../styles/theme.scss';

@keyframes roundAnimate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  width: $space-XL;
  height: $space-XL;
  border-radius: $space-M;
  border: pxToRem(2) solid $color-gray400;
  border-right: pxToRem(2) solid transparent;
  animation: roundAnimate 1s ease-in-out infinite;
}
