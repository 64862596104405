@import "@wingspanhq/fe-component-library/dist/theme.scss";

.item {
  padding-left: pxToRem(50);

  &::before {
    content: "";
    display: block;
    position: absolute;
    border-radius: 50%;
    width: pxToRem(10);
    height: pxToRem(10);
    border: pxToRem(2) solid $color-gray400;
    top: pxToRem(2);
    left: pxToRem(5);
    background-color: $color-white;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    border-left: pxToRem(2) dashed $color-gray400;
    left: pxToRem(11);
    top: pxToRem(30);
    bottom: -$space-S;
  }

  &.success {
    &::before {
      top: pxToRem(2);
      left: pxToRem(5);
      width: pxToRem(10);
      height: pxToRem(10);
      background-color: $color-green50;
      border-color: $color-green50;
    }

    .icon {
      position: absolute;
      top: pxToRem(6);
      left: pxToRem(9);
      width: pxToRem(6);
      height: $space-XS;
      border-left: pxToRem(1) solid $color-green500;
      border-bottom: pxToRem(1) solid $color-green500;
      transform: rotate(-45deg);
    }

    &::after {
      top: $space-XL;
    }
  }

  &.filled {
    &::before {
      top: pxToRem(2);
      left: pxToRem(5);
      width: pxToRem(10);
      height: pxToRem(10);
      background-color: $color-gray400;
    }

    &::after {
      top: $space-XL;
    }
  }

  &:last-child {
    &::after {
      content: none;
    }
  }
}
