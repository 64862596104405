@import "../../../styles/theme.scss";
@import "../../../styles/helpers.scss";

.badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.badgeNumber {
  padding: $space-XS $space-S;
}

.text {
  color: inherit;
}

.theme-dark {
  background-color: $color-gray600;
  color: $color-white;
}

.theme-neutral {
  background-color: $color-gray100;
  color: $color-gray500;
}

.theme-red {
  background-color: $color-red400;
  color: $color-white;
}

.theme-amber {
  background-color: $color-amber300;
  color: $color-white;
}

.theme-green {
  background-color: $color-green400;
  color: $color-white;
}

.theme-blue {
  background-color: $color-blue500;
  color: $color-white;
}

.theme-violet {
  background-color: $color-violet400;
  color: $color-white;
}

.size-XS {
  height: $space-S;
  border-radius: $space-XS;

  &:not(.badgeNumber) {
    width: $space-S;
    min-width: $space-S;
  }
}

.size-S {
  height: $space-L;
  border-radius: pxToRem(8);

  &:not(.badgeNumber) {
    width: $space-L;
    min-width: $space-L;
  }
}

.size-M {
  height: pxToRem(20);
  border-radius: pxToRem(10);

  &:not(.badgeNumber) {
    width: pxToRem(20);
    min-width: pxToRem(20);
  }
}

.size-L {
  height: $space-XL;
  border-radius: pxToRem(12);

  &:not(.badgeNumber) {
    width: $space-XL;
    min-width: $space-XL;
  }
}
