@import "@wingspanhq/fe-component-library/dist/theme.scss";

.wrapper {
  .main {
    padding: $space-2XL $space-XL;
    max-width: pxToRem(560);
    margin: 0 auto;
  }
}

.successMessage {
  border-radius: 0;
  border-left: 0;
  border-right: 0;
}

.manualTransferDetailsCard {
  box-shadow: $shadow-M;
}
