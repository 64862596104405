@import "@wingspanhq/fe-component-library/dist/theme.scss";

.boldText,
.link,
.text {
  font-size: $font-size-20;
  line-height: $line-height-28;

  @include breakpoint-min($breakpoint-S) {
    font-size: $font-size-24;
    line-height: $line-height-32;
  }

  @include breakpoint-min($breakpoint-M) {
    font-size: $font-size-36;
    line-height: $line-height-48;
  }
}

.boldText {
  font-weight: $font-weight-medium;
}
