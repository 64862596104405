@import "@wingspanhq/fe-component-library/dist/theme.scss";

.container {
  display: flex;
  gap: $space-M;

  > label:first-child {
    width: pxToRem(150);
  }
}
