@import "../../styles/theme.scss";

.banner {
  display: block;
  border-radius: pxToRem(4);
  overflow: hidden;
  padding: $space-M $space-L;
}

.bannerWrapper {
  position: relative;
  display: flex;
  align-items: start;
  justify-content: stretch;

  @include breakpoint-min($breakpoint-S) {
    align-items: center;
  }
}

.closeWrapper {
  padding-left: $space-M;
}

.iconWrapper {
  padding-right: $space-M;
}

.iconWrapper,
.closeWrapper {
  flex-shrink: 0;
  display: flex;

  @include breakpoint-min($breakpoint-S) {
    align-items: center;
  }
}

.content {
  display: block;
  flex: 1;
  min-width: 0;

  @include breakpoint-min($breakpoint-S) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
  }
}

.text {
  flex-grow: 1;
  display: block;
  display: -webkit-box;
  max-height: pxToRem(40);
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;

  @include breakpoint-min($breakpoint-S) {
    display: block;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-height: none;
    white-space: nowrap;
  }
}

.actions {
  margin-top: $space-M;
  display: block;
  flex: 100%;

  @include breakpoint-min($breakpoint-S) {
    display: flex;
    flex: auto;
    justify-content: flex-end;
    flex-shrink: 0;
    margin-top: 0;
    margin-left: $space-M;
  }
}

.action {
  margin-top: $space-M;
  margin-left: 0;

  @include breakpoint-min($breakpoint-S) {
    margin-top: 0;
    margin-left: $space-M;
  }

  &:focus {
    background: rgba(255, 255, 255, 0.2);
  }
}

.type-neutral {
  background-color: $color-blue100;

  .icon {
    color: $color-blue500;
  }
}

.type-info {
  background-color: $color-gray100;

  .icon {
    color: $color-gray600;
  }
}

.type-warning {
  background-color: $color-amber100;

  .icon {
    color: $color-amber400;
  }
}

.type-success {
  background-color: $color-green100;

  .icon {
    color: $color-green400;
  }
}

.type-error {
  background-color: $color-red100;

  .icon {
    color: $color-red400;
  }
}
