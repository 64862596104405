@import "@wingspanhq/fe-component-library/dist/theme.scss";

.annualTip {
  background-color: #fff2e8;
  border-radius: pxToRem(2);
  color: $color-gray700;
  padding: $space-XS;
}

.toggle {
  background-color: $color-gray700;
}

.savingTip {
  background-color: #fff2e8;
}

.satisfactionMsg {
  background-color: $color-gray50;
  border-radius: pxToRem(2);
}

.badgeIconContainer {
  background-color: $color-blue50;
  border-radius: pxToRem(4);
}
