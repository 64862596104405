@import "@wingspanhq/fe-component-library/dist/theme.scss";

.invoiceFooter {
  text-align: center;

  .name {
    margin-bottom: pxToRem(3);
    font-weight: $font-weight-medium;
    font-size: $font-size-16;
    line-height: $line-height-24;
    letter-spacing: 0.01em;
    color: $color-gray700;

    @include breakpoint-min($breakpoint-S) {
      font-size: $font-size-16;
      line-height: $line-height-24;
    }
  }

  .email {
    margin-bottom: pxToRem(3);

    a {
      font-weight: $font-weight-medium;
      font-size: $font-size-16;
      line-height: $line-height-20;
      color: #057ccc;
    }
  }

  .address {
    font-size: $font-size-16;
    line-height: $line-height-24;
    text-align: center;
    letter-spacing: 0.01em;
    color: $color-gray600;
  }

  .poweredBy {
    margin-top: pxToRem(20);
    font-weight: $font-weight-medium;
    font-size: $font-size-14;
    line-height: $line-height-20;
    letter-spacing: 0.01em;
    color: #6b727f;
  }

  .wingspanLogo {
    margin-top: pxToRem(9);
    width: pxToRem(100);
    margin: 0 auto;

    img {
      display: block;
      margin-top: pxToRem(10);
      width: 100%;
    }
  }
}
