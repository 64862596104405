@import "@wingspanhq/fe-component-library/dist/theme.scss";

.trigger {
  border: pxToRem(1) solid currentColor;
  border-radius: pxToRem(4);
  padding: pxToRem(6) $space-M;
}

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  overflow: hidden;
  cursor: auto;
}

.dropDown {
  display: block;
  position: absolute;

  box-shadow: $shadow-M;
  width: pxToRem(320);
  max-width: 88vw;
  background-color: $color-white;
  z-index: 1000;
}

.positionRight {
  left: 104%;
  top: 0;
}

.positionLeft {
  right: 104%;
  top: 0;
}

.triggerWrapper {
  cursor: pointer;
  display: inline-block;
  position: relative;
}
