@import "../../styles/theme.scss";

.labelWrapper {
  display: flex;
  gap: $space-XS;
  align-items: center;
  justify-content: space-between;

  &.actionLeft {
    justify-content: flex-start;
  }
}

.label {
  display: flex;
  gap: $space-S;
  align-items: center;
}
