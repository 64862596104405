@import "@wingspanhq/fe-component-library/dist/theme.scss";

.desktopNavContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  display: flex;
  overflow-y: auto;
  border-right: solid pxToRem(2) $color-gray200;
  z-index: 900;
}

.navigationHeader {
  width: 100%;
  height: $space-4XL;
  padding: $space-XL;
  flex-wrap: nowrap;
  background-color: $color-white;
  border-bottom: solid pxToRem(1) $color-gray200;

  @include breakpoint-min($breakpoint-S) {
    border-bottom-color: transparent;
  }
}

.hamburgerMenuIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -$space-XL;
  margin-right: 0;
  padding: $space-XL;
}

.tooltip {
  width: 100%;
}

.nav {
  background-color: $color-white;
  min-width: pxToRem(280);
  width: pxToRem(280);

  overflow-x: hidden;
  overflow-y: auto;
  transition: all 0.1s linear;

  i + * {
    white-space: nowrap;
  }

  &.collapsed {
    width: $space-4XL;
    min-width: $space-4XL;

    .navigationLogo,
    .partnerIABenefitsLogo,
    .partnerUSALogo {
      display: none;
    }

    .collapsibleMenu > p,
    .defaultMainMenuOption > p {
      display: none;
    }

    .collapsibleMenu {
      width: unset;
      padding-top: $space-S;
      padding-bottom: $space-S;
      padding-right: pxToRem(28);
      i[data-testid="menu-arrow-indicator"] {
        display: none;
      }
      &:hover {
        background-color: $color-gray50;
      }
    }
  }

  .navItemsContainer {
    background-color: $color-white;
    flex-wrap: unset;
    height: 100%;
  }

  .topNavItems {
    width: 100%;
    flex: 1;
  }

  .bottomNavItems {
    width: 100%;
    padding-bottom: $space-XL;
  }
}

.navMobile {
  position: static;
  min-width: pxToRem(240);
  z-index: 1000;
  width: pxToRem(300);
  min-height: 100vh;
  background-color: white;
  transition: all 250ms ease-in;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: $shadow-XL;

  .navItemsContainer {
    flex: 1;
    height: 100%;
    // min-height: 100vh;
    background-color: $color-white;
    flex-wrap: unset;
    margin-top: $space-XL;

    @include breakpoint-min($breakpoint-S) {
      height: 100%;
      margin-top: 0;
    }
  }

  .topNavItems {
    width: 100%;
  }

  .bottomNavItems {
    width: 100%;
  }
}

.collapsibleMenu {
  padding-top: pxToRem(10);
  padding-bottom: pxToRem(10);

  width: 100%;
  .label {
    flex: 1;
  }

  .icon {
    background: $color-gray50;
    border-radius: pxToRem(2);
    padding: pxToRem(2) pxToRem(6);
  }

  &:hover {
    background-color: $color-gray50;
  }
}

.defaultMainMenuOption {
  @extend .collapsibleMenu;
  flex-wrap: nowrap;

  &:hover {
    background-color: $color-gray50;
  }
}

.navLink {
  position: relative;
  width: 100%;
  color: $color-gray600;
}

.eventsCounter {
  position: absolute;
  left: pxToRem(36);
  top: $space-XS;
}

.activeNavLink {
  width: 100%;
  background-color: $color-blue50;
  i,
  p,
  span {
    color: $color-blue400 !important;
  }
}

.subMenuContainer {
  width: 100%;
  overflow: hidden;

  transition: all 0.2s linear;
  opacity: 0;
  max-height: 0;

  flex-wrap: nowrap;

  &.expanded {
    opacity: 1;
    display: contents;
    max-height: unset;
  }

  .subMenuOption {
    width: 100%;
    padding: $space-S;
    padding-left: $space-4XL;
    color: $color-gray600;

    &:hover {
      background-color: $color-gray50;
    }
  }
}

.mobileFooter {
  width: 100%;
  padding-bottom: $space-3XL;
}

.toolTipContent {
  max-width: pxToRem(300);
  background-color: $color-black;
  box-shadow: $shadow-XL;
  padding: $space-M;

  .arrow {
    border-color: transparent;
  }
}

.mobileNavContainer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100000;
  transition: all 0.25s linear;
  transform: translate3d(-100%, 0, 0);
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: $shadow-M;
  overflow-x: hidden;
  overflow-y: auto;
  opacity: 0;
}

.mobileNavOpened {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.copyLinkWrapper {
  flex: 1;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  bottom: 0;
  padding: $space-2XL 0 $space-M 0;
  width: 100%;
  align-self: flex-start;
  background: -webkit-linear-gradient(
    to bottom,
    rgba(256, 256, 256, 0.01),
    $color-white,
    $color-white,
    $color-white,
    $color-white
  );
  background: linear-gradient(
    to bottom,
    rgba(256, 256, 256, 0.01),
    $color-white,
    $color-white,
    $color-white,
    $color-white
  );
}

.copyLink {
  margin: auto auto 0 auto;
  background: $color-gray100;
  border-radius: pxToRem(100);
}
