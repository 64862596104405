@import "@wingspanhq/fe-component-library/dist/theme.scss";

.title {
  /**
    * This is a workaround for the fact that the "Required" text is a p tag
    * and as we are rendering this in WSInfoBox, it is adding a margin-bottom
    * to the p tag. This is a hack to remove that margin-bottom.
    */
  margin-bottom: 0px !important;
  margin-right: $space-S !important;
}
