@import "../../../../../node_modules/@wingspanhq/fe-component-library/dist/theme";

.badge {
  border-radius: $space-M;
}

.originalValue {
  background-color: $color-gray50;
}

.requestedValue {
  background-color: $color-amber50;
}

.list {
  font-size: $font-size-14;

  li {
    margin: 0;
  }
}
