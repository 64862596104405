@import "@wingspanhq/fe-component-library/dist/theme.scss";

.dateRange {
  label {
    min-width: pxToRem(240);
  }
}

.sticky {
  // All of this is breaking THE RULES and needs to go into the FECL
  // next sticky I see we're doing it!
  &::before {
    display: block;
    background-color: white;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 110vw;
    height: 100%;
    margin-left: -5vw;
  }
}

.filters {
  position: sticky;
  top: 0;
  padding: $space-M 0;
  margin: -$space-M 0;
  z-index: 10;
  background-color: $color-white;
}

.stickyActions {
  position: sticky;
  top: 0;
  padding: $space-M 0;
  margin: -$space-M 0;
  z-index: 100;
  background-color: $color-white;
}

.selectCategory {
  padding: 0 $space-M;
  height: pxToRem(52);
  border: pxToRem(1) solid $color-gray200;
  border-radius: pxToRem(2);
}

.buttonActionsText {
  flex: 1;
  border-right: solid $color-white pxToRem(1);
}

.buttonActionsIcon {
  padding: $space-M;
}

.buttonActionsWrapper {
  width: 100%;

  @include breakpoint-min($breakpoint-S) {
    width: auto;
  }
}

.buttonActions {
  background-color: $color-blue500;
  border-radius: pxToRem(4);
}

.reportItemName {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.notificationPanel {
  background: #fff2e8;
  border-radius: pxToRem(2);
  width: 100%;

  @include breakpoint-min($breakpoint-S) {
    width: auto;
  }
}
