@import "@wingspanhq/fe-component-library/dist/theme.scss";

.creditCardLogoCircle {
  background-color: $color-gray100;
  width: $space-2XL;
  height: $space-2XL;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: pxToRem(3);
}
