@import "../../../styles/theme.scss";
@import "../../../styles/helpers.scss";

.avatar {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  position: relative;
  overflow: visible;
}

.avatarContent {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.format-circle {
  .avatarContent {
    border-radius: 50%;
  }

  .badge {
    left: 75%;
    bottom: 75%;
  }

  .badgeNumber,
  .badgeIcon {
    left: 75%;
    bottom: 90%;
    transform: translate3d(0, 50%, 0);
  }
}

.badgeIcon,
.badgeNumber,
.badge {
  position: absolute;
  z-index: 1;
}

.badgeIcon,
.badgeNumber {
  border: solid pxToRem(2) $color-white;
}

.badge {
  border: solid pxToRem(1) $color-white;
}

.format-square {
  .avatarContent {
    border-radius: pxToRem(2);
  }

  .badge {
    left: 100%;
    bottom: 100%;
    transform: translate3d(-50%, 50%, 0);
  }

  .badgeNumber,
  .badgeIcon {
    left: 75%;
    bottom: 90%;
    transform: translate3d(0, 50%, 0);
  }

  &.size-S {
    .countryImage {
      height: pxToRem(16);
      width: pxToRem(16);
    }
  }

  &.size-M {
    .countryImage {
      height: pxToRem(20);
      width: pxToRem(20);
    }
  }

  &.size-L  {
    .countryImage {
      height: pxToRem(24);
      width: pxToRem(24);
    }
  }
}


.size-S,
.size-S > .avatarContent {
  height: pxToRem(24);
  width: pxToRem(24);
}

.size-M,
.size-M > .avatarContent {
  height: pxToRem(30);
  width: pxToRem(30);
}

.size-L,
.size-L > .avatarContent {
  height: pxToRem(36);
  width: pxToRem(36);
}

.wingspan {
  .avatarContent {
    background-color: transparent;
  }
}

.innerText {
  text-transform: capitalize;
}
