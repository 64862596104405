@import "@wingspanhq/fe-component-library/dist/theme.scss";

.text {
  max-width: pxToRem(420);
  min-height: $space-5XL;
}

.subHeaderInfo {
  height: pxToRem(52);
  background-color: $color-gray100;
}

.wrapper {
  .main {
    padding: $space-2XL $space-XL;
    max-width: pxToRem(560);
    margin: 0 auto;
  }
}

.successMessage {
  border-radius: 0;
  border-left: 0;
  border-right: 0;
}

.previewImage {
  img {
    max-width: pxToRem(300);
  }
}
