@import "../../styles/theme.scss";

.timeline {
  display: flex;
  flex-direction: column;
  gap: $space-L;
}

.item {
  position: relative;
  padding-left: pxToRem(40);

  &::before {
    content: "";
    display: block;
    position: absolute;
    border-radius: 50%;
    width: pxToRem(24);
    height: pxToRem(24);
    top: 0;
    left: 0;
    background-color: $color-gray400;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    border-left: pxToRem(2) dashed $color-gray300;
    left: pxToRem(11);
    top: pxToRem(24);
    bottom: -(pxToRem(24));
  }

  &:last-child {
    &::after {
      content: none;
    }
  }
}

.avatar {
  position: absolute;
  top: pxToRem(0);
  left: pxToRem(0);
}

.badges {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $space-M;

  .badge {
    position: relative;
    padding-right: pxToRem(17);
    margin-right: $space-M;

    &::after {
      content: "";
      display: block;
      position: absolute;
      border-radius: 50%;
      width: pxToRem(5);
      height: pxToRem(5);
      background-color: $color-gray200;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
    }

    &:last-child {
      padding-right: 0;
      margin-right: 0;

      &::after {
        content: none;
      }
    }
  }
}

.card {
  padding: $space-M;
  border: pxToRem(1) solid $color-gray200;
  border-radius: pxToRem(8);
}
