@import "@wingspanhq/fe-component-library/dist/theme.scss";

.accountsList {
  display: flex;
  flex-direction: column;
  gap: $space-L;
  width: 100%;
}

.account {
  padding: $space-M 0;
  min-height: pxToRem(52);

  &.hasSingleAccount {
    padding: 0;
    min-height: 0;
  }
}

.whiteInput {
  background-color: white;
}
