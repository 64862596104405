@import "@wingspanhq/fe-component-library/dist/theme";

.csvLinkBtn {
  width: 100%;
}

.actionBtn {
  flex: 1;
}

.bulletedList {
  li {
    margin-bottom: $space-M;
  }

  ol {
    list-style-type: decimal;
    padding-left: 0;
  }
}

.downloadBtn,
.uploadBtn {
  padding: $space-M;
}
