@import "@wingspanhq/fe-component-library/dist/theme.scss";

.hiddenCaptcha {
  display: none;
  visibility: collapse;
}

.isOpen {
  display: flex;
  visibility: visible;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: center;
  z-index: 99999;
}

.modal {
  min-width: pxToRem(320);
  max-width: 90vw;
  min-height: pxToRem(100);
  position: relative;
  background-color: $color-white;
  border-radius: pxToRem(4);

  padding: pxToRem(20);
  @include breakpoint-min($breakpoint-S) {
    padding: pxToRem(40);
  }
}

.closeIcon {
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 0;
}

.isHidden {
  display: none;
  visibility: collapse;
}
