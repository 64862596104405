@import "../../styles/theme";

.searchPanel {
  display: flex;
  flex-direction: column;
  gap: $space-S;

  @include breakpoint-min($breakpoint-S) {
    flex-direction: row;
    align-items: center;
    gap: $space-L;
  }
}

.inputContainer {
  flex: 1;
  display: flex;
  align-items: center;
  gap: $space-S;

  & > label {
    flex: 1;
  }

  @include breakpoint-min($breakpoint-S) {
    flex-direction: row;
    align-items: center;
    gap: $space-L;
  }
}

.backButton {
  display: flex;
  align-items: center;
  gap: $space-XS;
}
