@import '../../../styles/theme.scss';

.container {
  width: fit-content;
}

.dateInput {
  opacity: 0;
  position: fixed;
  z-index: -1;
}
