@import "@wingspanhq/fe-component-library/dist/theme.scss";

.title {
  text-align: center;
  margin: pxToRem(10) pxToRem(20) 0 pxToRem(20);
  font-family: $font-centra;
  font-weight: $font-weight-medium;
  font-size: $font-size-18;
  line-height: $line-height-24;
  color: $color-gray700;

  @include breakpoint-min($breakpoint-S) {
    margin: pxToRem(38) 0 0 0;
    font-size: $font-size-24;
    line-height: $line-height-36;
  }
}

.description {
  margin: $space-M pxToRem(20) $space-XL pxToRem(20);
  font-family: $font-centra;
  font-size: $font-size-16;
  line-height: $line-height-24;
  letter-spacing: 0.01em;
  color: $color-gray500;
  text-align: center;

  @include breakpoint-min($breakpoint-S) {
    font-size: $font-size-12;
    line-height: $line-height-24;
  }
}

.subtitle {
  display: flex;
  align-items: center;
  font-family: $font-centra;
  color: $color-gray500;
  font-weight: $font-weight-medium;
  font-size: $font-size-16;
  line-height: $line-height-20;
  margin: pxToRem(22) 0;
  i {
    width: pxToRem(10);
    min-width: pxToRem(10);
    color: $color-green400;
    margin-right: pxToRem(6);
  }
}

.info {
  background: white;
  box-shadow: $shadow-M;
  border-radius: pxToRem(2);
  max-width: pxToRem(382);
  padding: $space-M;
  margin-bottom: $space-M;
}

.infoTitle {
  font-weight: $font-weight-medium;
  font-size: $font-size-12;
  line-height: $line-height-16;
  color: $color-gray700;
}

.infoText {
  margin-top: $space-S;
  font-size: $font-size-10;
  line-height: $line-height-16;
  color: $color-gray500;
}
