@import "@wingspanhq/fe-component-library/dist/theme.scss";


.panel {
  height: 100%;

  > * {
    height: 100%;
  }
}

.divider {
  margin-top: auto;
}