@import "@wingspanhq/fe-component-library/dist/theme";

.previewCard {
  position: relative;
  border-radius: $space-M;
  min-width: pxToRem(334);
  height: pxToRem(250);
  background-repeat: no-repeat;
  background-position: center center;
  //background-size: 100% 100%;

  background-image: url("../../../../assets/images/wallet/previewCard.svg");
}