@import "../../../styles/theme.scss";

.wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color: $color-gray50;
  z-index: 1000;
}

.header {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $space-4XL;
  min-height: $space-4XL;
  max-height: $space-4XL;
  border: pxToRem(1) solid $color-gray200;

  @include breakpoint-min($breakpoint-M) {
    height: $space-5XL;
    min-height: $space-5XL;
    max-height: $space-5XL;
  }
}

.headerContainer {
  padding: 0 $space-M;
}

.headerCenter {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 50%;
  & > * {
    max-height: $space-XL;
    max-width: 100%;
  }
}

.content {
  flex: 1;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  flex-direction: column;
  padding: $space-M;

  @include breakpoint-min($breakpoint-M) {
    padding: $space-3XL $space-XL $space-XL $space-XL;
    flex-direction: row;
  }
}

.bodyRight,
.bodyLeft {
  padding: $space-M 0;

  @include breakpoint-min($breakpoint-M) {
    padding: $space-M;
    display: flex;
    justify-content: center;
    flex: 1;
  }
}

.bodyCenter {
  flex: 1;

  @include breakpoint-min($breakpoint-M) {
    min-width: pxToRem(380);
    max-width: pxToRem(380);
  }

  display: flex;
  flex-direction: column;
}
