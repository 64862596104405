@import "@wingspanhq/fe-component-library/dist/theme.scss";

.panel {
  padding: $space-M;

  @include breakpoint-min($breakpoint-S) {
    padding: $space-XL;
  }
}

.info {
  background-color: $color-gray50;

  .description {
    color: $color-gray600;
  }
}

.warning {
  background-color: $color-amber50;

  .description {
    color: $color-gray600;
  }
}

.error {
  background-color: $color-red50;

  .message {
    color: $color-red500;
  }

  .description {
    color: $color-gray600;
  }

  .icon {
    color: $color-red500;
  }
}
