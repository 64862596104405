@import "@wingspanhq/fe-component-library/dist/theme.scss";

.withFullscreen {
  overflow: hidden;
}

.fullscreen {
  overflow-y: auto;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 1000;
}
