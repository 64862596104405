@import "@wingspanhq/fe-component-library/dist/theme";

:root {
  touch-action: pan-x pan-y;

}

.flatfile_displayAsModal {
  z-index: 1200 !important; // side-panel z-index: 1100 so should be greater than this
}

body {
  margin: 0;
  overflow-x: hidden;
  width: 100vw;
}

* {
  position: relative;

  outline: 0;
  margin: 0;
  border: none;
  padding: 0;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  position: initial;
}

a {
  text-decoration: none;
  color: $color-blue400;
  cursor: pointer;
}
