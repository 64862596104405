@import "@wingspanhq/fe-component-library/dist/theme";

.header {
  height: pxToRem(90);
  border-bottom: pxToRem(1) solid $color-gray200;
}

.logo {
  height: pxToRem(35);
}
