@import "../../styles/theme.scss";

.field {
  width: 100%;
  display: block;
}

.top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: $space-S;

  &.stacked {
    align-items: flex-start;
    flex-direction: column;
    gap: $space-XS;
  }
}

.label {
  display: flex;
  align-items: center;
}

.action {
  padding: 0;
}

.hidden {
  display: none;
}
