@import "@wingspanhq/fe-component-library/dist/theme.scss";

.smallField {
  width: pxToRem(65);
}

.typeField {
  width: pxToRem(150);
}

.intervalField {
  width: pxToRem(98);
}
