@import '../../../styles/theme.scss';

.imageContainer {
  width: 100%;

  .image {
    display: block;
    width: 100%;
  }
}
