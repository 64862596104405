@import "@wingspanhq/fe-component-library/dist/theme.scss";

.dismissable {
}

.clickable {
  transition: box-shadow 0.3s ease-in-out;
}

.clickable:hover {
  box-shadow: $shadow-S;
}

.body {
  margin-top: $space-S;
  b {
    font-weight: $font-weight-medium;
    color: $color-gray600;
  }
}
