@import "../../../styles/theme.scss";

.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $color-white;
  overflow-y: auto;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-top: $space-M;
  padding-bottom: $space-M;

  @include breakpoint-min($breakpoint-S) {
    padding-top: $space-2XL;
    padding-bottom: $space-2XL;
  }
}

.line {
  border-bottom: pxToRem(2) solid $color-gray200;
  width: 100%;
}

.body {
  width: 100%;
}
