@import "../../../styles/theme.scss";

@mixin inputStyles {
  box-sizing: border-box;
  outline: none;
  width: 100%;
  height: pxToRem(44);
  padding: $space-M;
  background-color: $color-gray50;
  border: pxToRem(1) solid transparent;
  border-bottom: pxToRem(1) solid $color-gray500;

  font-family: $font-centra;
  font-size: $font-size-16;
  font-weight: normal;
  color: $color-gray700;
}

@mixin inputFocusStyles {
  border: pxToRem(1) solid $color-blue400;
}

@mixin inputErrorStyles {
  border: pxToRem(1) solid $color-red400;
  background-color: $color-red50;
}

.inputLabelContainer {
  .label {
    color: $color-gray500;
    margin-bottom: pxToRem(10);
  }

  .error {
    display: flex;

    .errorText {
      color: $color-gray700;
      margin-top: pxToRem(10);
    }
    .errorIcon {
      margin-top: pxToRem(14);
      margin-right: pxToRem(10);
      color: $color-red500;
    }
  }

  .inputContainer {
    > input {
      ::placeholder {
        opacity: 1;
        color: $color-gray700;
      }
    }
    width: 100%;
    .input {
      @include inputStyles;

      &:focus {
        @include inputFocusStyles;
      }

      &.error {
        @include inputErrorStyles;
      }
    }
  }
}
