@import "../../styles/theme";

:global {
  .p-datepicker {
    background: $color-white;
    color: $color-gray600;
    font-family: $font-centra;
    font-size: $font-size-14;
    line-height: $line-height-20;
    font-weight: $font-weight-book;
    border-radius: $space-XS;
    box-shadow: $shadow-M;
  }

  .p-datepicker .p-datepicker-header {
    padding: $space-L;
    background: $color-white;
    border-bottom: pxToRem(1) solid $color-gray200;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-prev,
  .p-datepicker .p-datepicker-header .p-datepicker-next {
    width: 2rem;
    height: 2rem;
    color: $color-gray600;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
  .p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
    color: $color-gray600;
    border-color: transparent;
    background: $color-gray50;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-title {
    display: flex;
    gap: $space-L;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    color: $color-blue400;
    font-weight: $font-weight-medium;

    font-family: $font-centra;
    font-size: $font-size-14;
    line-height: $line-height-20;
  }
  .p-datepicker
    .p-datepicker-header
    .p-datepicker-title
    .p-datepicker-year:enabled:hover,
  .p-datepicker
    .p-datepicker-header
    .p-datepicker-title
    .p-datepicker-month:enabled:hover {
    color: $color-blue500;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    margin-right: 0.5rem;
  }
  .p-datepicker table {
    margin: 0.5rem 0;
  }
  .p-datepicker table th {
    padding: 0.5rem;
  }
  .p-datepicker table th > span {
    width: 2.5rem;
    height: 2.5rem;
  }
  .p-datepicker table td {
    padding: 0.5rem;
  }

  .p-datepicker table td > span {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    color: $color-gray500;
  }
  .p-datepicker table td > span.p-disabled {
    color: $color-gray300;
  }
  .p-datepicker table td > span.p-highlight {
    color: $color-blue500;
    background-color: $color-blue50;
  }
  .p-datepicker table td.p-datepicker-today > span {
    color: $color-gray500;
    background-color: $color-gray50;
  }
  .p-datepicker table td.p-datepicker-today > span.p-highlight {
    color: $color-blue500;
    background-color: $color-blue50;
  }
  .p-datepicker .p-datepicker-buttonbar {
    padding: 1rem 0;
    border-top: 1px solid $color-gray200;
  }
  .p-datepicker .p-datepicker-buttonbar .p-button {
    width: auto;
  }
  .p-datepicker .p-timepicker {
    border-top: 1px solid $color-gray200;
    padding: 0.5rem;
  }
  .p-datepicker .p-timepicker button {
    width: 2rem;
    height: 2rem;
    color: $color-gray500;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-datepicker .p-timepicker button:enabled:hover {
    color: $color-gray500;
    border-color: transparent;
    background: $color-gray50;
  }
  .p-datepicker .p-timepicker button:last-child {
    margin-top: 0.2em;
  }
  .p-datepicker .p-timepicker span {
    font-size: 1.25rem;
  }
  .p-datepicker .p-timepicker > div {
    padding: 0 0.5rem;
  }
  .p-datepicker.p-datepicker-timeonly .p-timepicker {
    border-top: 0 none;
  }
  .p-datepicker .p-monthpicker {
    display: flex;
    flex-direction: column;
  }
  .p-datepicker .p-monthpicker .p-monthpicker-month {
    width: 100%;
    padding: $space-M;
  }
  .p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight,
  .p-datepicker .p-monthpicker .p-monthpicker-month:hover {
    background: $color-blue50;
    font-weight: $font-weight-medium;
  }
  .p-datepicker .p-yearpicker {
    display: flex;
    flex-direction: column;
  }
  .p-datepicker .p-yearpicker .p-yearpicker-year {
    width: 100%;
    padding: $space-M;
  }
  .p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
    background: $color-blue50;
    font-weight: $font-weight-medium;
  }
  .p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
    border-left: 1px solid $color-gray200;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    padding-top: 0;
    padding-bottom: 0;
  }
  .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
    padding-left: 0;
  }
  .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
    padding-right: 0;
    border-left: 0 none;
  }
  .p-datepicker:not(.p-disabled)
    table
    td
    span:not(.p-highlight):not(.p-disabled):hover {
    background: $color-gray50;
  }
  .p-datepicker:not(.p-disabled)
    .p-yearpicker
    .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
    background: $color-gray50;
  }

  @media screen and (max-width: 769px) {
    .p-datepicker table th,
    .p-datepicker table td {
      padding: 0;
    }
  }
}

.input::-webkit-date-and-time-value {
  text-align: right;
}