@import "@wingspanhq/fe-component-library/dist/theme.scss";

.wingspanMark {
  background: $color-gray50;
  border-radius: pxToRem(2);
}

.receipt {
  position: relative;

  .receiptLoader,
  .receiptImage {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .receiptImage {
    z-index: 1;
    background-position: center center;
    background-size: cover;
  }
}

.selectCategory {
  padding: 0 $space-M;
  height: pxToRem(52);
  border: pxToRem(1) solid $color-gray200;
  border-radius: pxToRem(2);
}

.isBusiness {
  padding: 0 $space-M;
  height: pxToRem(52);
  border: pxToRem(1) solid $color-gray200;
  border-radius: pxToRem(2);
}

.note {
  padding: $space-M;
  border: pxToRem(1) solid $color-gray200;
  border-radius: pxToRem(2);
}

.noteText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  // a hack for correct ellipsis
  max-width: calc(100% - $space-4XL);
}

.drawer {
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 1000;

  @include breakpoint-min($breakpoint-M) {
    position: relative;
    left: auto;
    top: auto;
    width: auto;
    height: auto;
  }
}
