@import "../../../styles/theme.scss";

.wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color: $color-white;
  z-index: 1000;
}

.header {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: pxToRem(44);
  min-height: pxToRem(44);
  max-height: pxToRem(44);

  &:not(.smallHeader) {
    @include breakpoint-min($breakpoint-M) {
      height: $space-4XL;
      min-height: $space-4XL;
      max-height: $space-4XL;
    }
  }
}

.headerIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $space-M;
  margin: -$space-M;
}

.headerCenter {
  max-width: 80%;
}

.headerTitle {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.emptyLine,
.line {
  min-height: pxToRem(1);
  width: 100%;
  position: relative;
}

.line {
  background-color: $color-gray200;
}

.emptyLine {
  background-color: transparent;
}

.progress {
  position: absolute;
  top: 0;
  left: 0;
  height: pxToRem(1);
  width: 0;
  background-color: $color-garnet;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
}

.body {
  flex: 1;

  display: flex;
  flex-direction: column;
}

.footer {
}
