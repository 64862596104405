@import "@wingspanhq/fe-component-library/dist/theme.scss";

.separator {
  padding-left: $space-3XL;
  border-left: pxToRem(2) solid $color-gray100;
}

.higherPointsList {
  li {
    font-size: 0.875rem;
    margin-bottom: 0 !important;
  }
}

.lowerAlphaList {
  list-style-type: lower-alpha;

  li {
    font-size: 0.875rem;
    margin-bottom: 0 !important;
  }
}
