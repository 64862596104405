@import "@wingspanhq/fe-component-library/dist/theme.scss";

.headerContent {
  width: pxToRem(250);
}
.noDocumentsImg {
  width: pxToRem(125);
  height: pxToRem(100);
}

.selectedFile {
  border: 1px solid $color-gray300;
  border-radius: $space-S;
}

.selectedFileName {
  flex: 1;
}