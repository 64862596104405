@import "@wingspanhq/fe-component-library/dist/theme.scss";

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: pxToRem(1) solid $color-gray200;
  border-radius: $space-2XL;
  padding: pxToRem(10) $space-L;
}
