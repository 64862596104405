@import '../../../styles/theme.scss';

.nav {
  padding: $space-XL 0;
  position: relative;
  width: 100%;
  background-color: $color-white;
  height: calc(100vh - pxToRem(150));
  overflow-y: auto;

  .footer {
    border-top: pxToRem(2) solid $color-gray200;

    position: fixed;
    background-color: $color-white;
    padding: $space-M $space-XL;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
  }
}
