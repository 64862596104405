@import "../../../styles/theme.scss";

.pageContainer {
  margin-top: pxToRem(72);

  @include breakpoint-min($breakpoint-S) {
    margin-top: $space-2XL;
  }

  &.breadCrumb {
    @include breakpoint-min($breakpoint-M) {
      margin-top: pxToRem(52);
    }
  }
}

.link {
  text-decoration: none;
}
