@import "../../styles/theme";

.Vertical {
  width: 100%;

  > * {
    width: 100%;
  }
}

.Horizontal {
  > * {
    width: auto;
    min-width: 0;
  }
}

.equalItems {
  > * {
    flex-grow: 1;
    flex-basis: 0;
    align-self: normal;
  }
}
