@import '../../../styles/theme.scss';

.base {
  overflow: visible;
  position: relative;
  display: block;
  border-color: $color-gray200;
  border-style: solid;
  border-width: pxToRem(1);
  border-radius: pxToRem(4);
  width: 100%;
  box-sizing: border-box;

  &.noBorder {
    border-width: 0;
  }

  &.BS0 {
    box-shadow: none;
  }

  &.BS1 {
    box-shadow: $shadow-M;
  }

  &.BS2 {
    box-shadow: $shadow-L;
  }

  &.BS3 {
    box-shadow: $shadow-XL;
  }

  .closeIcon {
    position: absolute;
    z-index: 10;
    top: $space-XL;
    right: $space-XL;
    color: $color-gray500;
    cursor: pointer;
  }
}
