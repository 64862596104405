@import "@wingspanhq/fe-component-library/dist/theme.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  background-color: $color-gray50;
  min-height: 100vh;

  .headerBanner {
    background-color: white;
    text-align: center;
  }

  .main {
    .backButton {
      margin-bottom: $space-XL;

      @include breakpoint-min($breakpoint-M) {
        position: absolute;
        top: pxToRem(58);
        left: -(pxToRem(290));
      }
    }

    .mainContainer {
      margin: 0 auto;
      max-width: pxToRem(400);
      padding: $space-3XL $space-XL;
    }
  }
}
