@import "@wingspanhq/fe-component-library/dist/theme.scss";

.question {
  .questionText {
    white-space: unset;
    @include breakpoint-min($breakpoint-M) {
      white-space: pre-line;
    }
  }
  @include breakpoint-min($breakpoint-S) {
    .questionText {
      width: pxToRem(400);
    }
  }

  .form {
    width: 100%;
    display: flex;
    flex-direction: column;

    @include breakpoint-min($breakpoint-S) {
      align-items: center;
    }

    .option {
      padding: $space-M;
      border: pxToRem(1) solid $color-gray400;
      border-radius: pxToRem(4);
    }

    @include breakpoint-min($breakpoint-S) {
      .option {
        width: pxToRem(400);
      }
      .submitBtn {
        width: pxToRem(400);
      }
    }
  }
}
