@import "@wingspanhq/fe-component-library/dist/theme.scss";

.form {
  padding: 0;

  .field {
    margin-bottom: $space-L;
  }

  .errorText {
    color: $color-red400;
  }
}
