@import "@wingspanhq/fe-component-library/dist/theme.scss";

.drawerBase {
  padding: 0;

  .subject {
    flex: 1;
  }

  .subjectInput {
    flex: 1;
    font-size: $font-size-16;
    line-height: $line-height-20;
    color: $color-gray600;
  }

  .content {
    padding: 0 pxToRem(20);
    max-width: pxToRem(620);
    margin: 0 auto;

    .header {
      padding: pxToRem(20) 0;
      border-bottom: pxToRem(1) solid $color-gray200;
      text-align: center;

      @include breakpoint-min($breakpoint-S) {
        padding: pxToRem(40) 0;
      }
    }

    .section {
      border-bottom: pxToRem(1) solid $color-gray200;
      padding: pxToRem(20);

      @include breakpoint-min($breakpoint-S) {
        padding: pxToRem(25) pxToRem(30) pxToRem(35);
      }

      .label {
        margin-bottom: pxToRem(10);
        font-weight: $font-weight-medium;
        font-size: $font-size-16;
        line-height: $line-height-20;
        color: $color-gray600;
      }

      .text {
        font-size: $font-size-16;
        line-height: $line-height-20;
        color: $color-black;
      }
    }

    .subSection {
      margin-bottom: pxToRem(20);
    }

    .footer {
      padding: pxToRem(20) 0 pxToRem(40);

      @include breakpoint-min($breakpoint-S) {
        padding: pxToRem(40) 0;
      }
    }
  }
}
