@import "../../styles/theme.scss";

.valueWrapper {
  display: flex;
  gap: $space-XS;
  align-items: center;
  justify-content: space-between;
}

.value {
  display: flex;
  gap: $space-XS;
  align-items: center;
}

.image {
  max-width: pxToRem(160);
}
