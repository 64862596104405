@import "@wingspanhq/fe-component-library/dist/theme.scss";

$color-blue: #1b91e0;

button.base {
  border-radius: pxToRem(4);

  &.primary {
    background-color: $color-blue400;
    border-color: $color-blue400;
    color: white;
    border-width: pxToRem(2);

    &:hover {
      opacity: 0.85;
    }

    &.withLoader {
      color: $color-blue400;

      &:after {
        border-top-color: $color-blue400;
        color: white;
      }
    }
  }

  &.secondary {
    background-color: white;
    border-color: $color-blue400;
    color: $color-blue400;
    border-width: pxToRem(2);

    &:hover {
      opacity: 0.85;
    }

    &.withLoader {
      color: white;

      &:after {
        border-top-color: white;
        color: $color-blue400;
      }
    }
  }
}

button.baseLink {
  color: $color-blue400;

  &:hover {
    opacity: 0.85;
  }
}

.label {
  display: flex;
  align-items: center;
}
