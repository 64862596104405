@import '../../../styles/theme';

.container {
  width: 100%;

  &:focus {
    outline: none;
    border: none;
  }
}

.option {
  width: 100%;
  height: pxToRem(40);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: pxToRem(1) solid $color-gray400;
  border-radius: $space-4XL;

  & + & {
    margin-top: $space-M;
  }
}

.selected {
  border: pxToRem(2) solid $color-green500;
}
