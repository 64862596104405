@import "@wingspanhq/fe-component-library/dist/theme.scss";

.item {
  padding-left: pxToRem(50);

  &::before {
    content: "";
    display: block;
    position: absolute;
    border-radius: 50%;
    width: pxToRem(20);
    height: pxToRem(20);
    border: pxToRem(2) solid $color-gray400;
    top: pxToRem(-3);
    left: 0;
    background-color: $color-white;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    border-left: pxToRem(2) dashed $color-gray400;
    left: pxToRem(11);
    top: pxToRem(30);
    bottom: -$space-S;
  }

  &.completed {
    &::before {
      top: pxToRem(2);
      left: pxToRem(5);
      width: pxToRem(10);
      height: pxToRem(10);
      background-color: $color-gray400;
    }

    &::after {
      top: $space-XL;
    }
  }

  &:last-child {
    &::after {
      content: none;
    }
  }
}
