@import "../../styles/theme.scss";

.tableCell {
  display: flex;
  align-items: center;
  gap: $space-S;
  min-width: 0;
}

.main {
  flex: 1;
  min-width: 0;
}

.text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.secondaryText {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.twoLines {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  white-space: wrap;
  word-wrap: break-word;
}

.pill + p {
  margin-top: $space-XS;
}

.pill {
  p {
    display: none;
  }

  @include breakpoint-min($breakpoint-S) {
    p {
      display: block;
    }
  }
}
