@import "../../../styles/helpers";
@import "../../../styles/theme";

:global {
  .p-tieredmenu {
    background: $color-white;
    border: 1px solid $color-gray100;
    border-radius: 0.375rem;
    min-width: pxToRem(228);
    z-index: $overlay-zIndex;
  }
  .p-tieredmenu.p-tieredmenu-overlay {
    background: $color-white;
    border: 0 none;
    box-shadow: $shadow-S;
  }
  .p-tieredmenu .p-tieredmenu-root-list {
    padding: $space-S 0;
    outline: 0 none;
  }
  .p-tieredmenu .p-submenu-list {
    position: absolute;
    padding: $space-S 0;
    background: $color-white;
    border: 0 none;
    border-radius: pxToRem(2);
    box-shadow: $shadow-S;
    min-width: pxToRem(180);
    width: 100%;
  }
  .p-tieredmenu .p-menuitem > .p-menuitem-content {
    transition: none;
    border-radius: 0;
  }
  .p-tieredmenu .p-menuitem > .p-menuitem-content .p-menuitem-link {
    user-select: none;
  }

  .p-tieredmenu .p-menuitem.p-highlight > .p-menuitem-content {
    background: $color-gray100;
  }
  .p-tieredmenu .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    background: $color-gray100;
  }
  .p-tieredmenu
    .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
    > .p-menuitem-content {
    background: $color-gray50;
  }
  .p-tieredmenu
    .p-menuitem:not(.p-highlight):not(.p-disabled)
    > .p-menuitem-content:hover {
    background: $color-gray100;
  }
  .p-tieredmenu .p-menuitem-separator {
    margin: 0.25rem 0;
  }
}

.item {
  cursor: pointer;
}


.icon {
  line-height: $line-height-24;
}

.destructive:hover {
  background: $color-red50;
}

.sidebar {
  border-radius: pxToRem(8) pxToRem(8) 0 0;
}
