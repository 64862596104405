@import "@wingspanhq/fe-component-library/dist/theme.scss";

.content {
  padding: $space-XL;
}

.header {
  max-height: pxToRem(52);
  min-height: pxToRem(52);
}

@include breakpoint-min($breakpoint-S) {
  .content {
    background-color: $color-gray50;

    &.whiteBackground {
      background-color: white;
    }
  }

  .wrapper {
    display: flex;
    justify-content: center;
  }

  .container {
    width: 100%;
    max-width: pxToRem(800);
    margin: 0 auto;
    padding: $space-XL;

    &.narrow {
      max-width: pxToRem(464);
    }
  }
}
