@import "@wingspanhq/fe-component-library/dist/theme.scss";

.point {
  font-family: $font-centra;
  width: $space-XL;
  height: $space-XL;
  min-width: $space-XL;
  border: pxToRem(2) solid $color-blue500;
  border-radius: $space-M;
  color: $color-blue500;
  font-weight: $font-weight-medium;
  font-size: $font-size-16;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.01em;
  background-color: white;

  &.current,
  &.checked {
    background-color: $color-blue500;
    color: white;
  }

  i {
    width: pxToRem(10);
    color: white;
  }
}
