@import "@wingspanhq/fe-component-library/dist/theme";


.virtualCardPreview {
  position: relative;
  border-radius: $space-S;
  width: 100%;
  height: pxToRem(209);

  overflow: hidden;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% 100%;

  background-image: url("../../../assets/images/wallet/gettingStartedVirtualCard.svg");
}
