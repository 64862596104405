@import "@wingspanhq/fe-component-library/dist/theme.scss";

.uploadFileButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border: pxToRem(2) dashed $color-gray400;
  background-color: $color-gray200;
  width: $space-5XL;
  height: pxToRem(60);
}

.action {
  cursor: pointer;
}
