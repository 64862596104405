@import "@wingspanhq/fe-component-library/dist/theme.scss";

.photo {
  width: pxToRem(35);
  height: pxToRem(35);
  background-color: $color-gray200;
  border-radius: pxToRem(30);
  overflow: hidden;
  flex: 1 0 pxToRem(35);

  @include breakpoint-min($breakpoint-S) {
    width: pxToRem(60);
    height: pxToRem(60);
    flex: 0 0 pxToRem(60);
  }
}

.card {
  padding: $space-XL;
  background-color: #fff2e8;
  border-radius: $space-M;

  .msg {
    white-space: unset;
    @include breakpoint-min($breakpoint-M) {
      white-space: pre-line;
    }
  }
}
