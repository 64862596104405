@import '../../../styles/theme.scss';

.overlay {
  z-index: 100;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &.white {
    background-color: $color-white;
    opacity: 0.6;
  }
}
