@import "../../../styles/theme.scss";

.withModal {
  overflow: hidden;
}

.wrapper {
  position: fixed;
  background-color: rgba(4, 4, 15, 0.4);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $overlay-zIndex;
  overflow-y: auto;
  overflow-x: hidden;

  @include breakpoint-min($breakpoint-S) {
    padding: $space-6XL 0;
  }

  &.blockClose {
    cursor: not-allowed;
  }
}

.base {
  cursor: auto;
  box-sizing: border-box;
  background-color: $color-white;
  box-shadow: $shadow-2XL;
  overflow-y: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  max-height: 80%;
  height: 90%;
  width: 100%;
  z-index: $overlay-zIndex;

  @include breakpoint-min($breakpoint-S) {
    overflow-y: visible;
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    max-width: none;
    max-height: none;
    height: auto;
    margin: 0 auto;
    z-index: auto;
  }

  &.fullScreen {
    position: absolute;
    overflow-y: auto;
    box-sizing: border-box;
    background-color: $color-white;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    max-width: none;
    max-height: none;
  }

  &.centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:focus {
    outline: 0;
  }

  .closeIcon {
    position: absolute;
    z-index: 1;
    top: $space-XL;
    right: $space-XL;
    color: $color-gray500;
    cursor: pointer;
    &.blockClose {
      cursor: not-allowed;
    }
  }

  &.size {
    &-XS {
      max-width: pxToRem(320);

      .closeIcon {
        top: $space-M;
        right: $space-M;
        &.blockClose {
          display: none;
        }
      }
    }

    &-S {
      max-width: pxToRem(480);

      .closeIcon {
        top: $space-M;
        right: $space-M;
        &.blockClose {
          display: none;
        }
      }
    }

    &-M {
      max-width: pxToRem(720);
    }

    &-L {
      max-width: pxToRem(960);
    }

    &-XL {
      max-width: pxToRem(1200);
    }
  }
}
