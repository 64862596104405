@import '../../../styles/theme.scss';
@import '../../../styles/helpers.scss';

:global {
  .p-sidebar {
    background: $color-white;
    border: 0 none;
    box-shadow: $shadow-L;
  }

  .p-component-overlay {
    background-color: $overlay-color;
    transition-duration: 200ms;
  }

  .p-component-overlay-enter {
    background-color: $overlay-color;
    transition-timing-function: ease-in;
  }

  .p-component-overlay-leave {
    background-color: transparent;
    transition-timing-function: ease-out;
  }

  .p-sidebar-top.p-sidebar {
    height: auto;
    max-height: 50%
  }
}

.mask {
  z-index: $overlay-zIndex !important;
}

.sidebar {
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  cursor: auto;
}

.sidebarContent {
  position: static;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  width: 100%;
}

.sidebarContainerContent {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: stretch;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .close {
    position: absolute;
    right: $space-XL;
    top: pxToRem(6);
    z-index: 1;
    padding: pxToRem(8);
    cursor: pointer;
    border-radius: 50%;

    &:hover {
      background-color: $color-gray100;
    }
  }
}

.sidebarBody {
  position: static;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

@include breakpoint-min($breakpoint-S) {
  .top,
  .bottom {
    &.sizeS {
      max-height: pxToRem(320);
      height: pxToRem(320);
    }

    &.sizeM {
      max-height: pxToRem(480);
      height: pxToRem(480);
    }

    &.sizeL {
      max-height: pxToRem(640);
      height: pxToRem(640);
    }

    &.sizeAUTO {
      height: auto;
    }
  }

  .left,
  .right {
    &.sizeS {
      max-width: pxToRem(320);
      width: pxToRem(320);
    }

    &.sizeM {
      max-width: pxToRem(480);
      width: pxToRem(480);
    }

    &.sizeL {
      max-width: pxToRem(640);
      width: pxToRem(640);
    }

    &.sizeAUTO {
      width: auto;
    }
  }
}

.top,
.bottom {
  height: 100%;
  width: 100%;

  &.sizeAUTO {
    height: auto;
  }
}

.left,
.right {
  width: 100%;
  height: 100%;

  &.sizeAUTO {
    width: auto;
  }
}

.sidebarHeader {
  height: pxToRem(48);
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: $space-S $space-XL;
  border-bottom: pxToRem(1) solid $color-gray200;
}


.sidebarFooter {
  width: 100%;
  background-color: $color-white;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  padding: $space-S $space-XL;
  border-top: pxToRem(1) solid $color-gray200;
}

.clickableMask {
  cursor: pointer;
}
