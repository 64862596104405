@import "@wingspanhq/fe-component-library/dist/theme.scss";

.toolbar {
  display: flex;
  flex-direction: column;
  gap: $space-M;

  @include breakpoint-min($breakpoint-M) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
