@import "@wingspanhq/fe-component-library/dist/theme";
@import "index.module";

.placeholderCard {
  @extend .cardBasic;

  &.physicalPlaceholderCard {
    background-image: url("../../../../assets/images/wallet/physicalPlaceholderCard.svg");
  }

  &.virtualPlaceholderCard {
    background-image: url("../../../../assets/images/wallet/virtualPlaceholderCard.svg");
  }

  .cardLink {
    position: absolute;
    bottom: $space-L;
    left: $space-L;
    z-index: 1;
  }
}
