@import "@wingspanhq/fe-component-library/dist/theme.scss";

.column {
  overflow: visible !important;
}

.select {
  flex: 1;
}

.highlightedActivity {
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: pxToRem(6);
    left: -$space-M;
    background-color: $color-blue300;
    width: pxToRem(6);
    height: pxToRem(6);
    border-radius: 50%;
  }
}
