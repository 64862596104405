@import '../../../styles/theme.scss';

.fileInputContainer {
  .fileInput {
    &.hide {
      display: none;
    }
  }
}

.dropFileButton {
  background-color: $color-white;
}
