@import "@wingspanhq/fe-component-library/dist/theme.scss";

.subscriptionPackageCard {
  position: relative;
  background-color: $color-white;
  border: pxToRem(1) solid $color-gray200;
  border-radius: $space-S;
  width: 100%;

  @include breakpoint-min($breakpoint-M) {
    margin-right: pxToRem(20);
    max-width: pxToRem(286);
  }

  &.popular {
    border: pxToRem(1) solid #1b91e0;
  }

  .description {
    min-height: pxToRem(40);
  }

  .valuePropostionsContainer {
    min-height: pxToRem(260);

    &.effectiveDateBadge {
      min-height: pxToRem(142);
    }
    &.cancelButton {
      min-height: pxToRem(140);
    }

    &.addOnProps {
      min-height: pxToRem(136);
    }
  }

  .btnText {
    white-space: nowrap;
  }
}

.popularBadge {
  border-radius: $space-M;
  background-color: #e1f3ff;
  text-transform: uppercase;

  span {
    color: $color-blue400 !important;
  }
}

.cancelInfoBadge {
  border-radius: $space-M;
}
