@import "@wingspanhq/fe-component-library/dist/theme.scss";

.listContainer {
  list-style: none;
  padding: 0;
}

.listRow {
  display: flex;
  justify-content: space-between;
}
