@import "../../../styles/theme.scss";
@import "../../../styles/helpers.scss";

.chip {
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;
  height: pxToRem(40);
  padding: $space-S $space-M;
  border: 1px solid $color-gray300;
  border-radius: pxToRem(21);
  background-color: $color-white;
  user-select: none;
  min-width: 0;
  gap: $space-M;
  max-width: 100%;
  max-width: -webkit-fill-available;
}

.chip.clickable:hover {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.chip.clickable:active,
.chip.active {
  border-color: $color-gray600;

  .text {
    color: $color-gray600;
    white-space: nowrap;
  }
}
