@import "@wingspanhq/fe-component-library/dist/theme.scss";

.header {
  width: 100%;
  border-bottom: pxToRem(1) solid $color-gray700;

  &.hideBorder {
    border-bottom: 0;
  }

  @include breakpoint-min($breakpoint-S) {
    padding: $space-3XL 0;
  }

  .backIcon {
    position: absolute;
    top: 50%;
    left: pxToRem(20);
    transform: translateY(-50%);
    color: $color-gray500;
    width: pxToRem(20);
    cursor: pointer;

    @include breakpoint-min($breakpoint-S) {
      width: pxToRem(36);
    }
  }

  .logoLink {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .logo {
      width: pxToRem(166);

      @include breakpoint-min($breakpoint-S) {
        width: pxToRem(266);
      }
    }
  }

  .partnerHeader {
    display: flex;
    align-items: center;
    width: 100%;

    .leftLogo {
      flex: 1;
      display: flex;
      flex-direction: row-reverse;

      .logo {
        width: pxToRem(166);

        @include breakpoint-min($breakpoint-S) {
          width: pxToRem(266);
        }
      }
    }

    .rightLogo {
      flex: 1;

      .rightImage {
        max-height: $space-XL;

        @include breakpoint-min($breakpoint-S) {
          max-height: pxToRem(36);
        }
      }
    }
  }
}
