@import "@wingspanhq/fe-component-library/dist/theme.scss";

.drawer {
  padding: 0;
  background-color: $color-gray50;
}

.wrapper {
  position: relative;
  z-index: 1000;
  min-height: 100%;
  background-color: $color-gray50;

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    height: pxToRem(36);
    border-bottom: pxToRem(1) solid $color-gray200;

    @include breakpoint-min($breakpoint-S) {
      border-bottom-width: pxToRem(2);
    }

    .backIcon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: $space-XL;
    }
  }

  .main {
    padding: $space-XL;
  }
}

.card {
  background: white;
  border-radius: pxToRem(6);

  .cardHeader {
    padding: $space-XL;
    border-bottom: pxToRem(1) solid $color-gray50;
  }

  .main {
    padding: $space-XL;
  }
}
