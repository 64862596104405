@import '../../../styles/theme.scss';

.base {
  box-sizing: border-box;
  display: flex;
  cursor: pointer;

  .control {
    background-color: $color-white;
    border: pxToRem(1) solid #abaeb5;
    width: $space-XL;
    min-width: $space-XL;
    height: $space-XL;
    border-radius: pxToRem(2);
    position: relative;
    margin: 0 $space-M 0 0;

    &:after {
      width: pxToRem(5);
      height: $space-M;
      border: solid $color-white;
      border-width: 0 pxToRem(2) pxToRem(2) 0;
      -webkit-transform: translate(-50%, -50%) rotate(45deg);
      -ms-transform: translate(-50%, -50%) rotate(45deg);
      transform: translate(-50%, -50%) rotate(45deg);
      position: absolute;
      top: 40%;
      left: 50%;
    }
  }

  .label {
    line-height: $space-XL;
  }

  &:hover {
    .control {
      background-color: $color-gray200;
    }
  }

  &.checked {
    .control {
      background-color: $color-blue400;
      border: pxToRem(1) solid $color-blue400;

      &:after {
        content: '';
      }
    }
  }

  &.disabled {
    opacity: 0.35;

    &:hover {
      .control {
        background-color: $color-white;
      }

      &.checked {
        .control {
          background-color: $color-blue400;
        }
      }
    }
  }
}
