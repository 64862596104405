@import "@wingspanhq/fe-component-library/dist/theme.scss";

.pageToolbar {
  display: flex;
  flex-direction: column;
  gap: $space-S;

  @include breakpoint-min($breakpoint-M) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.actions {
  width: 100%;
  @include breakpoint-min($breakpoint-S) {
    width: auto;
  }
}