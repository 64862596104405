@import "../../../styles/theme.scss";
@import "../../../styles/helpers";

.navigationContainer {
  position: relative;
}

.buttonPrevious,
.buttonNext {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  background-color: $color-white;
  border-bottom: pxToRem(1) solid $color-gray300;
}

.buttonPrevious {
  left: 0;
  padding-right: $space-M;
}

.buttonNext {
  right: 0;
  padding-left: $space-M;
}

.navigationContent {
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  scrollbar-width: none;
  overscroll-behavior: contain auto;
  position: relative;
}

.navigation {
  display: flex;
  border-bottom: pxToRem(1) solid $color-gray300;
}

.item {
  display: flex;
  align-items: center;
  position: relative;
  padding: $space-S $space-L;
  margin-right: $space-S;

  @include breakpoint-min($breakpoint-XL) {
    margin-right: $space-2XL;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: -(pxToRem(1));
    left: 0;
    right: -$space-S;
    height: pxToRem(1);
    background-color: $color-gray300;

    @include breakpoint-min($breakpoint-XL) {
      right: -$space-2XL;
    }
  }

  &:last-child {
    margin-right: 0;

    &:before {
      right: 0;
    }
  }
}

.label {
  font-weight: $font-weight-medium;
  color: $color-gray400;
  text-wrap: nowrap;
}

.active {
  .label {
    color: $color-gray700;
  }

  .badgeText {
    background-color: $color-gray600;
    color: $color-white;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: -(pxToRem(1));
    left: 0;
    right: 0;
    height: pxToRem(1);
    background-color: $color-gray700;
  }
}

.content {
  margin-top: $space-XL;
}
