@import "@wingspanhq/fe-component-library/dist/theme.scss";

.settingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: $space-2XL;
  border-bottom: pxToRem(1) solid $color-gray200;
  margin-bottom: $space-3XL;

  &:last-child {
    margin-bottom: 0;
  }
}
