@import "@wingspanhq/fe-component-library/dist/theme.scss";

.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: pxToRem(44);
  border-bottom: pxToRem(2) solid $color-gray200;

  padding: 0 pxToRem(15);
  @include breakpoint-min($breakpoint-S) {
    padding: 0 pxToRem(26);
    height: pxToRem(68);
    background-size: auto pxToRem(22);
  }

  &.withSteps {
    background-image: none;
  }

  > div[tabindex] {
    margin: 0;
    width: pxToRem(17);
    height: pxToRem(17);

    @include breakpoint-min($breakpoint-S) {
      width: $space-XL;
      height: $space-XL;
    }
  }
}

.back {
  width: pxToRem(13);
  height: pxToRem(13);
  min-width: pxToRem(13);
  color: $color-gray500;
  cursor: pointer;

  @include breakpoint-min($breakpoint-S) {
    width: $space-XL;
    height: $space-XL;
  }
}

.progress {
  display: block;
  width: 100%;
  position: absolute;
  left: 0;
  height: pxToRem(2);
  background-color: $color-garnet;
  bottom: pxToRem(-2);
  z-index: 1;
}

.steps {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;

  .step {
    display: flex;
    align-items: center;

    &:after {
      content: "";
      display: block;
      border-bottom: solid pxToRem(2) #dbf0ff;
      width: $space-XL;
      margin: 0 $space-M;
    }

    &:last-child:after {
      display: none;
    }
  }

  .stepPoint {
  }

  .stepTitle {
    display: none;
    font-weight: $font-weight-medium;
    font-size: $font-size-16;
    line-height: $line-height-24;
    letter-spacing: 0.01em;
    color: $color-gray600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0 0 0 $space-M;

    @include breakpoint-min($breakpoint-S) {
      display: block;
    }
  }
}

.content {
  position: relative;
  width: 100%;
}
