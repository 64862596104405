@import "@wingspanhq/fe-component-library/dist/theme.scss";

.base {
  background-color: #fefaf8;
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .main {
    flex: 1;

    .headingWrapper {
      margin: pxToRem(30) auto;
      padding: 0 pxToRem(20);
      max-width: pxToRem(660);
      text-align: center;

      @include breakpoint-min($breakpoint-S) {
        margin: pxToRem(60) auto;
      }
    }

    .formWrapper {
      margin: pxToRem(30) auto;
      padding: 0 pxToRem(20);
      max-width: pxToRem(420);

      @include breakpoint-min($breakpoint-S) {
        margin: pxToRem(60) auto;
      }
    }
  }
}

.emailPreview {
  padding: $space-M;
  border: $color-gray400 solid pxToRem(1);
  border-radius: pxToRem(4);
}

.userIcon {
  border: $color-gray200 solid pxToRem(1);
  background-color: $color-white;
  border-radius: $space-L;
  width: $space-2XL;
  min-width: $space-2XL;
  height: $space-2XL;
}

.userDetails {
  overflow: hidden;
}
