@import "@wingspanhq/fe-component-library/dist/theme.scss";

.item {
  padding-left: pxToRem(40);

  &::before {
    content: "";
    display: block;
    position: absolute;
    border-radius: 50%;
    width: pxToRem(14);
    height: pxToRem(14);
    top: pxToRem(2);
    left: pxToRem(5);
    background-color: $color-gray400;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    border-left: pxToRem(2) dashed $color-gray400;
    left: pxToRem(11);
    top: pxToRem(20);
    bottom: pxToRem(-20);
  }

  &:last-child {
    &::after {
      content: none;
    }
  }
}

.badge {
  padding-right: pxToRem(17);

  &::after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 50%;
    width: pxToRem(5);
    height: pxToRem(5);
    background-color: $color-gray400;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }

  &:last-child {
    padding-right: 0;

    &::after {
      content: none;
    }
  }
}
