@import "../../../styles/theme.scss";

.checkboxItem {
  padding: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: pointer;

  & + .checkboxItem {
    margin: $space-M 0 0 0;
  }

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

/* Create a custom checkbox */
.checkbox {
  background-color: $color-white;
  border: pxToRem(1) solid #abaeb5;
  width: $space-XL;
  min-width: $space-XL;
  height: $space-XL;
  min-height: $space-XL;
  border-radius: pxToRem(2);
  position: relative;
  margin: 0 $space-M 0 0;
}

// Thanks to W3Schools ;)

/* On mouse-over, add a grey background color */
.checkboxItem:hover input ~ .checkbox {
  background-color: $color-gray200;
}

/* When the checkbox is checked, add a blue background */
.checkboxItem input:checked ~ .checkbox {
  background-color: $color-blue400;
  border: pxToRem(1) solid $color-blue400;
}

/* Create the checkbox/indicator (hidden when not checked) */
.checkbox:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkbox when checked */
.checkboxItem input:checked ~ .checkbox:after {
  display: block;
}

/* Style the checkbox/indicator */
.checkboxItem .checkbox:after {
  width: pxToRem(5);
  height: $space-M;
  border: solid $color-white;
  border-width: 0 pxToRem(2) pxToRem(2) 0;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  -ms-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
  position: absolute;
  top: 40%;
  left: 50%;
}

.checkboxGroup {
  display: flex;
  flex-direction: column;
  outline: none;
}
