@import "../../styles/theme.scss";

.breadcrumbs {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  min-width: 0;
}

.currentBreadcrumb,
.breadcrumb {
  flex-shrink: 1;
  min-width: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:last-of-type:not(:last-child) {
    margin-right: $space-XS;
  }
}

.breadcrumbIcon {
  margin: pxToRem(2) pxToRem(6);
}

.backIcon {
  margin: pxToRem(2);
}

.hiddenBreadcrumbs {
  padding: pxToRem(4) pxToRem(16);

  &:hover i {
    color: $color-gray600;
  }
}

.clickable {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: $color-gray600;
  }
}

.backButton:hover {
  .backIcon,
  .breadcrumb {
    text-decoration: underline;
    color: $color-gray600;
  }
}
