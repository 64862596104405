@import "../../../styles/theme.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: $space-M;
  padding: $space-M;
  border-radius: pxToRem(4);
  background: $color-gray50;

  @include breakpoint-min($breakpoint-M) {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }
}
