@import "@wingspanhq/fe-component-library/dist/theme.scss";

.everyField {
  width: pxToRem(100);
}

.weeksFieldLabel {
  width: pxToRem(90);
}

.weeksField {
  flex: 1;
}