@import "@wingspanhq/fe-component-library/dist/theme.scss";

.card {
  iframe {
    position: absolute;
    border: 0 solid transparent;
    width: pxToRem(240);
    height: pxToRem(20);
    background-color: $color-blue400;
  }

  .maskedCardNumber {
    iframe {
      display: none;
    }
    position: absolute;
    top: pxToRem(30);
    left: pxToRem(30);
  }

  .cardNumber {
    iframe {
      top: pxToRem(30);
      left: pxToRem(30);

      overflow-y: scroll;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* Internet Explorer 10+ */

      &::-webkit-scrollbar {
        /* WebKit */
        width: 0;
        height: 0;
      }
    }
  }

  .expiry {
    position: absolute;
    bottom: pxToRem(20);
    left: pxToRem(30);
  }

  .cvv {
    iframe {
      width: pxToRem(40);
      top: pxToRem(130);
      right: pxToRem(30);

      overflow-y: scroll;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* Internet Explorer 10+ */

      &::-webkit-scrollbar {
        /* WebKit */
        width: 0;
        height: 0;
      }
    }
  }
  position: relative;
  background-color: $color-blue400;
  width: pxToRem(300);
  height: pxToRem(170);
  border-radius: $space-M;
  margin-bottom: $space-XL;
  color: $color-white;
}
