@import "@wingspanhq/fe-component-library/dist/theme.scss";

.gridContainer {
  margin-top: $space-3XL;
}

.videoContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.costExampleLineItems {
  align-items: initial !important;

  .lineItem {
    font-size: $font-size-10;
  }
}

.ctaWidget {
  .headerContainer {
    background-color: $color-gray50;
  }
  .logo {
    max-width: pxToRem(200);
  }
}

.contactInfo {
  background-color: $color-blue50;
  border-radius: pxToRem(2);
}

.wingspanLogoContainer {
  border-radius: $space-M;
  background-color: $color-garnet;
  width: $space-3XL;
  height: $space-3XL;

  .wsLogo {
    width: $space-2XL;
    height: $space-L;
  }
}

.transitionLogo {
  width: $space-3XL;
  height: $space-3XL;
  align-content: center;

  img {
    width: unset !important;
  }
}
