@import "@wingspanhq/fe-component-library/dist/theme.scss";

.receiptPDF {
  position: relative;
  width: 100%;

  .receiptPDFBlocker {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}
