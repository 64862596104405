@import "../../styles/theme.scss";

$divider-height: 1px;

.divider {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: $divider-height;
  transform: translate3d(0, 0, 1rem);

  &::after {
    position: absolute;
    content: "";
    display: block;
    height: $divider-height;
    background-color: currentColor;
    left: 0;
    right: 0;
    top: 50%;
  }

  &.type-split {
    justify-content: space-between;
  }

  &.type-expand {
    position: initial;
    left: 0;
    right: 0;
  }
}

.label {
  margin-right: $space-M;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: $color-white;

  &::before,
  &::after {
    position: absolute;
    content: "";
    display: block;
    top: 0;
    bottom: 0;
    background-color: $color-white;
  }

  &::before {
    left: -$space-M;
    width: $space-M;
  }

  &::after {
    right: -$space-M;
    width: $space-M;
  }
}

.action {
  position: relative;
  z-index: 1;
  background-color: $color-white;
  padding: 0;

  &::before,
  &::after {
    position: absolute;
    content: "";
    display: block;
    top: 0;
    bottom: 0;
    background-color: $color-white;
  }

  &::before {
    left: -$space-M;
    width: $space-M;
  }

  &::after {
    right: -$space-M;
    width: $space-M;
  }
}

.label ~ .action {
  padding-left: 0;
}

.divider.type-split {
  .label {
    padding-left: 0;
  }

  .action {
    padding-right: 0;
  }
}
