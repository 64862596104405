@import "../../styles/theme.scss";

$gaps: (
  "S": $space-S,
  "M": $space-M
);

.actions {
  display: flex;
  max-width: 100%;
}

.button {
  flex-shrink: 1;
  min-width: 0; // Allows the button to shrink, enabling text truncation
  max-width: 100%; // Optional: Define a max-width for the button
}

.buttonText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100%);
}

.alignment-left {
  align-items: center;
  justify-content: flex-start;
}

.alignment-right {
  align-items: center;
  justify-content: flex-end;
}

.alignment-center {
  align-items: center;
  justify-content: center;
}

.alignment-fill {
  flex-wrap: nowrap;
  justify-content: stretch;
  align-items: center;
  width: 100%;

  .fillButton {
    flex: auto;
  }
}

.orientation-horizontal {
  flex-wrap: nowrap;
}

.orientation-vertical {
  flex-wrap: wrap;
  width: 100%;

  .button {
    flex-basis: 100%;
  }

  .button:last-of-type {
    flex: 1;
  }

  .menuIcon {
    margin: auto;
  }
}

@each $spaceName, $spaceValue in $gaps {
  .size-#{$spaceName} {
    gap: $spaceValue;
  }
}
