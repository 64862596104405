@import "../../styles/theme.scss";

.fullWidth {
  display: flex;
  width: 100%;
  max-width: 100%;
  max-width: -webkit-fill-available;

  align-items: center;
  justify-content: center;

  &.container {
    max-width: none;
  }
}

.orientation-vertical {
  &.container {
    max-width: pxToRem(280);
  }

  .wordBreak {
    word-break: break-word;
    text-align: center;
  }

  .textContent {
    align-items: center;

    .description {
      text-align: center;
      margin-bottom: $space-XL;
    }

    .button {
      margin-bottom: $space-XL;
    }
  }
}

.orientation-horizontal {
  &.container {
    max-width: pxToRem(580);
  }

  .wordBreak {
    word-break: break-word;
    text-align: left;
  }

  .textContent {
    align-items: flex-start;
    gap: $space-XS;

    .description {
      text-align: left;
      margin-bottom: $space-M;
    }

    .button {
      margin-bottom: $space-M;
    }
  }
}

.bg-transparent {
  background-color: transparent;
}

.bg-white {
  background-color: $color-white;
}

.bg-gray {
  background-color: $color-gray50;
}

.border {
  border-radius: pxToRem(8);
  border: pxToRem(1) solid $color-gray200;
}

.img-S {
  width: pxToRem(125);
  height: pxToRem(100);
  max-width: 100%;
}

.img-M {
  width: pxToRem(250);
  height: pxToRem(200);
  max-width: 100%;
}

.textContent {
  &.size-S {
    max-width: pxToRem(195);
  }

  &.size-M {
    max-width: pxToRem(320);
  }
}
