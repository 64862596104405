/* Thin */

@font-face {
  font-family: 'Centra No2';
  src: url('../assets/fonts/CentraNo2/CentraNo2-Thin.woff2') format('woff2'),
    url('../assets/fonts/CentraNo2/CentraNo2-Thin.eot')
      format('embedded-opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Centra No2';
  src: url('../assets/fonts/CentraNo2/CentraNo2-ThinItalic.woff2')
      format('woff2'),
    url('../assets/fonts/CentraNo2/CentraNo2-ThinItalic.eot')
      format('embedded-opentype');
  font-weight: 100;
  font-style: italic;
}

/* Light */

@font-face {
  font-family: 'Centra No2';
  src: url('../assets/fonts/CentraNo2/CentraNo2-Light.woff2') format('woff2'),
    url('../assets/fonts/CentraNo2/CentraNo2-Light.eot')
      format('embedded-opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Centra No2';
  src: url('../assets/fonts/CentraNo2/CentraNo2-LightItalic.woff2')
      format('woff2'),
    url('../assets/fonts/CentraNo2/CentraNo2-LightItalic.eot')
      format('embedded-opentype');
  font-weight: 300;
  font-style: italic;
}

/* Book */

@font-face {
  font-family: 'Centra No2';
  src: url('../assets/fonts/CentraNo2/CentraNo2-Book.woff2') format('woff2'),
    url('../assets/fonts/CentraNo2/CentraNo2-Book.eot')
      format('embedded-opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Centra No2';
  src: url('../assets/fonts/CentraNo2/CentraNo2-BookItalic.woff2')
      format('woff2'),
    url('../assets/fonts/CentraNo2/CentraNo2-BookItalic.eot')
      format('embedded-opentype');
  font-weight: 400;
  font-style: italic;
}

/* Medium */

@font-face {
  font-family: 'Centra No2';
  src: url('../assets/fonts/CentraNo2/CentraNo2-Medium.woff2') format('woff2'),
    url('../assets/fonts/CentraNo2/CentraNo2-Medium.eot')
      format('embedded-opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Centra No2';
  src: url('../assets/fonts/CentraNo2/CentraNo2-MediumItalic.woff2')
      format('woff2'),
    url('../assets/fonts/CentraNo2/CentraNo2-MediumItalic.eot')
      format('embedded-opentype');
  font-weight: 500;
  font-style: italic;
}

/* Bold */

@font-face {
  font-family: 'Centra No2';
  src: url('../assets/fonts/CentraNo2/CentraNo2-Bold.woff2') format('woff2'),
    url('../assets/fonts/CentraNo2/CentraNo2-Bold.eot')
      format('embedded-opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Centra No2';
  src: url('../assets/fonts/CentraNo2/CentraNo2-BoldItalic.woff2')
      format('woff2'),
    url('../assets/fonts/CentraNo2/CentraNo2-BoldItalic.eot')
      format('embedded-opentype');
  font-weight: 700;
  font-style: italic;
}
