@import "../../styles/theme.scss";

.label {
  display: flex;
  gap: $space-S;
  align-items: center;
}

.top {
  display: flex;
  gap: $space-S;
  align-items: center;
}

.link {
  padding: 0;
}

.helperLink {
  padding: 0;
}
