@import "@wingspanhq/fe-component-library/dist/theme.scss";

.cardWithBorder {
  border: pxToRem(1) solid $color-gray200;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: pxToRem(43);
  height: pxToRem(43);
  background-color: $color-gray50;
  border-radius: 50%;
}

.title {
  flex: 1;
}
