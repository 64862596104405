@import "@wingspanhq/fe-component-library/dist/theme";

.csvLinkBtn {
  width: 100%;
}

.actionBtn {
  flex: 1;
}

.stateTaxIdField {
  margin-bottom: $space-XL;
}

.buttonActionsWrapper {
  width: 100%;

  @include breakpoint-min($breakpoint-S) {
    width: pxToRem(240);
  }
}

.buttonActions {
  background-color: $color-blue500;
  border-radius: pxToRem(4);
  justify-content: space-between;
}

.buttonActionsText {
  flex: 1;
  border-right: solid $color-white pxToRem(1);
}

.bulletedList {
  li {
    margin-bottom: $space-M;
  }

  ol {
    list-style-type: decimal;
    padding-left: 0;
  }
}

.downloadBtn,
.uploadBtn {
  padding: $space-M;
}

.drawer {
  padding: 0;
  background-color: $color-gray50;
}

.wrapper {
  position: relative;
  z-index: 1000;
  min-height: 100%;
  background-color: $color-gray50;

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 0 $space-XL;
    height: pxToRem(36);
    border-bottom: pxToRem(1) solid $color-gray200;
    box-sizing: content-box;

    @include breakpoint-min($breakpoint-S) {
      height: $space-4XL;
      border-bottom-width: pxToRem(2);
    }

    .backIcon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: $space-XL;
    }
  }

  .main {
    padding: $space-XL;
  }
}
