@import '../../../styles/helpers';

.container {
  position: relative;
}

.inline {
  display: inline-block;
}

.size_XS {
  height: pxToRem(24);
}

.size_S {
  height: pxToRem(32);
}

.size_M {
  height: pxToRem(48);
}

.size_L {
  height: pxToRem(64);
}

.img {
  height: 100%;
  object-fit: contain;
}
