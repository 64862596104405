.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1099;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &.white {
    background-color: white;
    opacity: 0.6;
  }

  &.overParent {
    position: absolute;
  }
}
