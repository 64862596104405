@import '../../../styles/theme.scss';

.shimmer {
  display: block;
  width: 100%;
}

.svg {
  max-width: 100%;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loaderIcon {
  color: $color-blue400;
  border-style: solid;
  border-radius: 50%;
  border-top-color: transparent;
  animation: rotate 1s linear infinite;

  &.L {
    border-width: pxToRem(5);
    height: $space-3XL;
    width: $space-3XL;
  }

  &.M {
    height: $space-XL;
    width: $space-XL;
    border-width: pxToRem(3);
  }

  &.S {
    border-width: pxToRem(2);
    height: pxToRem(18);
    width: pxToRem(18);
  }

  &.XS {
    border-width: pxToRem(1);
    height: pxToRem(14);
    width: pxToRem(14);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
