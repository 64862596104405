@import "@wingspanhq/fe-component-library/dist/theme.scss";

.base {
  max-width: pxToRem(325);
  margin: pxToRem(65) auto 0;
  text-align: center;
  color: $color-gray500;

  a {
    color: $color-gray500;
    text-decoration: underline;
  }
}
