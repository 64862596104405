@import "@wingspanhq/fe-component-library/dist/theme.scss";

.toolTipContent {
  background-color: $color-white;
  box-shadow: $shadow-XL;
  padding: $space-M;

  .arrow {
    border-color: transparent;
  }

  &[x-placement="top"] {
    .arrow {
      border-top-color: $color-white;
    }
  }

  &[x-placement="right"] {
    .arrow {
      border-right-color: $color-white;
    }
  }

  &[x-placement="bottom"] {
    .arrow {
      border-bottom-color: $color-white;
    }
  }

  &[x-placement="left"] {
    .arrow {
      border-left-color: $color-white;
    }
  }
}
