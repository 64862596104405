@import '../../../styles/theme.scss';

.container {
  display: block;
  width: 100%;
  max-width: pxToRem(240);
}

.stars {
  width: 100%;
}

.star {
  margin: 0;
}
