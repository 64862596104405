@import "../../../styles/theme";

.select {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  position: relative;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  min-width: $space-4XL;
  height: pxToRem(44);
  padding: $space-M;
  background-color: $color-gray50;
  border: pxToRem(1) solid transparent;
  border-bottom: pxToRem(1) solid $color-gray500;

  &:focus-within {
    border: pxToRem(1) solid $color-blue400;
  }

  &.error {
    border: pxToRem(1) solid $color-red400;
    background-color: $color-red50;
  }
}

.input {
  position: static;
  flex: 1;
  background-color: transparent;
  font-family: $font-centra;
  font-size: $font-size-16;
  font-weight: normal;
  color: $color-gray700;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
  outline: none;
  border: none;
  min-width: pxToRem(40);
}

.cleanIconWrapper {
  background-color: $color-white;
  border-radius: pxToRem(10);
  width: pxToRem(20);
  height: pxToRem(20);
  position: relative;
}

.icon {
  &.open {
    transform: rotate(180deg);
  }
  &.clean {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}

.options {
  display: none;
  position: absolute;
  overflow-y: auto;
  max-height: pxToRem(200);
  width: 100%;
  left: 0;
  top: 102%;
  background-color: $color-white;
  box-shadow: $shadow-XL;
  z-index: 1000;
  font-family: $font-centra;
  font-size: $font-size-16;
  line-height: $line-height-24;

  &.open {
    display: block;
  }
}

.option {
  cursor: pointer;
  z-index: 1;
  padding: $space-M;
  border-bottom: pxToRem(1) solid $color-gray200;

  &:last-child {
    border-bottom: 0;
  }

  &.selected {
    background-color: $color-gray50;
  }

  &:hover {
    background-color: $color-gray50;
  }
}

.action {
  color: $color-blue500;
  display: flex;
  align-items: center;
}

.mobileView {
  padding: $space-L;
  border-radius: pxToRem(8) pxToRem(8) 0 0;
}
