@import "@wingspanhq/fe-component-library/dist/theme.scss";

.requestTemplateForm {
  padding: 0;
}

.requestTemplateInputContainer {
  margin: $space-XL 0;
}

.requestTemplateInput {
  width: pxToRem(612) !important;
}

.requestTemplateInputMobile {
  width: 100% !important;
}
