@import '../../../styles/theme.scss';

// Basic flex props

.container {
  display: flex;
  box-sizing: border-box;
}

$alignContentValues: stretch, center, flex-start, flex-end, space-between,
  space-around;

@each $value in $alignContentValues {
  .alignContent-#{$value} {
    align-content: $value;
  }
}

$alignItemsValues: stretch, center, flex-start, flex-end, baseline;

@each $value in $alignItemsValues {
  .alignItems-#{$value} {
    align-items: $value;
  }
}

$justifyValues: center, flex-start, flex-end, space-between, space-around,
  space-evenly;

@each $value in $justifyValues {
  .justify-#{$value} {
    justify-content: $value;
  }
}

$directionValues: row, row-reverse, column, column-reverse;

@each $value in $directionValues {
  .direction-#{$value} {
    flex-direction: $value;
  }
}

$wrapValues: nowrap, wrap, wrap-reverse;

@each $value in $wrapValues {
  .wrap-#{$value} {
    flex-wrap: $value;
  }
}

@each $name, $value in $extended-spaces {
  .gap-#{$name} {
    gap: $value;
  }
}
