@import "@wingspanhq/fe-component-library/dist/theme.scss";

.videoContainer {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  border-radius: pxToRem(4);
  overflow: hidden;

  .video {
    background-color: $color-gray200;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.helperHeaderContainer {
  background-color: $color-gray50;
}
