@import "../../../styles/theme.scss";

.cardsContainer {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;

  scrollbar-width: none; /* Firefox 64 */
  -ms-overflow-style: none; /* Internet Explorer 11 */
  &::-webkit-scrollbar {
    /** WebKit */
    display: none;
    width: 0;
    height: 0;
  }

  .gridContainer {
    position: relative;
    display: flex;
    gap: $space-XL;

    .item {
      > div {
        height: 100%;
      }
    }

    .endCard {
      padding: $space-XL;
      height: 100%;
      border: pxToRem(1) dashed $color-gray200;
      align-self: stretch;
      box-sizing: border-box;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
