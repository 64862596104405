@import "../../../../node_modules/@wingspanhq/fe-component-library/dist/theme";

.fieldView {
  width: 100%;
  min-width: 0;
  max-width: 100%;
  flex: 1;
}

.labelWrapper {
  width: 100%;
}