@import "@wingspanhq/fe-component-library/dist/theme.scss";

.wrapper {
  width: 100%;
  max-width: pxToRem(504);
}

.checkbox {
  border: pxToRem(2) solid $color-blue500;
  width: $space-L;
  height: $space-L;
  min-width: $space-L;
  border-radius: pxToRem(2);
  cursor: pointer;
  transition: background-color ease 0.3s;

  @include breakpoint-min($breakpoint-S) {
    width: $space-L;
    height: $space-L;
    min-width: $space-L;
  }

  &.checked {
    background-color: $color-blue500;
    background-image: url("../../../assets/icons/check_white_16.svg");
    background-position: center;
    background-size: 80% 80%;
  }
}

.group {
  width: 100%;
  padding-right: pxToRem(20);
  @include breakpoint-min($breakpoint-S) {
    padding-right: 0;
  }
}

.groupHeader {
  display: flex;
  align-items: center;
  border-bottom: pxToRem(2) solid $color-gray200;
}

.groupHeaderBody {
  flex: 1;
  padding: pxToRem(14) 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.groupTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;

  font-weight: $font-weight-medium;
  font-size: $font-size-16;
  line-height: $line-height-20;
  color: $color-gray700;
  margin: 0;
}

.description {
  font-size: $font-size-10;
  line-height: $line-height-16;
  color: $color-gray500;
}

.action {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.actionIcon {
  color: $color-gray500;
  width: pxToRem(14);
  height: pxToRem(14);
}

.transactions {
  margin-left: pxToRem(10);
  @include breakpoint-min($breakpoint-S) {
    margin-left: pxToRem(30);
  }
}

.transaction {
  display: flex;
  align-items: center;
  cursor: pointer;

  & + & {
    border-top: pxToRem(1) solid $color-gray200;
  }
}

.transactionBody {
  flex: 1;
  padding: pxToRem(13) 0;
}

.transactionTitle {
  margin: 0;
  font-size: $font-size-12;
  line-height: $line-height-16;
  color: $color-gray700;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.category {
  background: $color-gray100;
  border-radius: $space-M;
  .categoryText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: $space-5XL;

    @include breakpoint-min($breakpoint-S) {
      max-width: pxToRem(152);
    }
  }
}
