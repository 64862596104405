@import "@wingspanhq/fe-component-library/dist/theme.scss";

.statsContainer {
  justify-content: flex-end;
}

.resultsStats {
  align-self: flex-end;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: $space-S;
}