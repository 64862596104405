@import "@wingspanhq/fe-component-library/dist/theme";

.badge {
  border-radius: $space-M;
}

.originalValue {
  background-color: $color-gray50;
  position: relative;
}

.originalBadge {
  position: absolute;
  top: $space-XL;
  right: $space-XL;
}


.list {
  font-size: $font-size-14;

  li {
    margin: 0;
  }
}

.separator {
  padding-left: $space-3XL;
  border-left: pxToRem(2) solid $color-gray50;
}
