@import "./WSInput.module.scss";

.stripeInput {
  @include inputStyles;
}

.stripeInputFocused {
  @include inputFocusStyles;
}

.stripeInputError {
  @include inputErrorStyles;
}

.stripeInputErrorMessage {
  display: flex;

  .errorText {
    color: $color-gray700;
    margin-top: pxToRem(10);
  }

  .errorIcon {
    margin-top: pxToRem(14);
    margin-right: pxToRem(10);
    color: $color-red500;
  }
}

.hideUntilReady {
  visibility: hidden;
}
