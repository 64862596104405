@import "@wingspanhq/fe-component-library/dist/theme.scss";

.headwayNotification {
  width: 100%;
  min-width: $space-4XL;
  min-height: pxToRem(40);
  position: relative;
  cursor: pointer;

  * {
    pointer-events: none;
  }

  &:hover {
    background-color: $color-gray50;
  }

  .indicatorNew {
    position: absolute;
    top: $space-XS;
    left: pxToRem(36);
    height: $space-M;
    width: $space-M;
    border-radius: $space-M;
    background-color: $color-blue300;
    z-index: 1;
  }

  .headwayWidget {
    position: absolute;
    top: 0;
    right: 0;
    * {
      opacity: 0 !important;
    }
  }
}

.label {
  flex: 1;
}

html[data-headway] *[id="HW_frame_cont"] {
  display: block;
}

html *[id="HW_frame_cont"] {
  display: none;
  left: 50% !important;
  top: 50% !important;
  transform: translate3d(-50%, -50%, 0) !important;
  box-shadow: 0 0 pxToRem(100) pxToRem(100) rgba(0, 0, 0, 0.05);
}
