@import "@wingspanhq/fe-component-library/dist/theme.scss";

.accountContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  .from,
  .to {
    width: 100%;
  }

  .icon {
    margin: $space-M 0;
  }

  @include breakpoint-min($breakpoint-S) {
    flex-direction: row;

    .icon {
      margin: 0 $space-2XL;
    }
  }
}

.addAccountButton {
  height: pxToRem(64);
  color: $color-gray500;
  border-color: $color-gray400;
  border-width: pxToRem(2);

  &:hover {
    background-color: transparent;
    color: $color-gray400;
  }
}

.badge {
  display: flex;
  align-items: center;
  background-color: $color-gray50;
  padding: $space-L $space-M;
  border-radius: pxToRem(2);
  height: pxToRem(64);

  > label {
    border: 0;
    padding: 0;
    height: auto;

    &:focus-within {
      border: 0;
    }
  }
}
