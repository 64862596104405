@import "@wingspanhq/fe-component-library/dist/theme";

.cardBasic {
  position: relative;
  border-radius: $space-S;
  width: pxToRem(334);
  height: pxToRem(209);
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}