@import "../../../styles/theme.scss";

.container {
  display: block;
  width: 100%;
  border-radius: pxToRem(2);
  box-shadow: $shadow-M;
  padding: 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $space-M pxToRem(20);
  border-bottom: pxToRem(2) solid $color-gray200;

  @include breakpoint-min($breakpoint-S) {
    padding-top: pxToRem(18);
    padding-bottom: pxToRem(20);
    padding-left: $space-XL;
    padding-right: $space-XL;
  }

  @include breakpoint-min($breakpoint-M) {
    padding-top: pxToRem(18);
    padding-bottom: pxToRem(20);
    padding-left: $space-XL;
    padding-right: $space-XL;
  }
}

.childrenContainer {
  display: block;
  padding: $space-XL;
}
