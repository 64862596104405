@import "@wingspanhq/fe-component-library/dist/theme.scss";

.helpButton {
  position: fixed;
  bottom: $space-2XL;
  right: $space-2XL;
  padding: $space-M;
  display: flex;
  align-items: center;
  z-index: 500;
  border: pxToRem(2) solid $color-blue400;
  border-radius: pxToRem(20);

  .icon {
    color: $color-blue400;
    margin-right: $space-XS;
  }

  .text {
    color: $color-blue400;
  }
}
