@import "@wingspanhq/fe-component-library/dist/theme.scss";

.disabled {
  opacity: 0.5;
}

.transferSchema {
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon {
    margin: $space-M 0;
  }

  .badge {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: $color-gray50;
    padding: pxToRem(6) $space-M;
    border-radius: pxToRem(4);
  }

  @include breakpoint-min($breakpoint-S) {
    flex-direction: row;

    .icon {
      margin: 0 $space-XL;
    }

    .badge {
      width: auto;
    }
  }
}

.info {
  display: flex;
  padding: $space-XL;
  background-color: $color-gray50;
  align-items: center;
}
