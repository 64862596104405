@import "@wingspanhq/fe-component-library/dist/theme.scss";

.section {
  border-bottom: pxToRem(1) solid #e8eaef;

  &:last-of-type {
    border-bottom: 0;
    padding-bottom: 0;
  }

  ul {
    li {
      cursor: pointer;
      &:last-child {
        margin-bottom: 0;
      }

      .name {
        > * {
          margin-right: $space-M;
        }
      }
    }
  }
}
