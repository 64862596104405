@import "../../../styles/theme.scss";

$container-padding-XS: pxToRem(20);
$container-padding-S: pxToRem(20);
$container-padding-M: pxToRem(20);
$container-padding-L: pxToRem(20);
$container-padding-XL: $space-2XL;

.base {
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;
  max-width: 88rem;
  padding: 0 $container-padding-XS;

  @include breakpoint-min($breakpoint-S) {
    padding: 0 $container-padding-S;
  }

  @include breakpoint-min($breakpoint-M) {
    padding: 0 $container-padding-M;
  }

  @include breakpoint-min($breakpoint-L) {
    padding: 0 $container-padding-L;
  }

  @include breakpoint-min($breakpoint-XL) {
    padding: 0 $container-padding-XL;
  }
}

.verticalPadding {
  padding: $container-padding-XS;

  @include breakpoint-min($breakpoint-S) {
    padding: $container-padding-S;
  }

  @include breakpoint-min($breakpoint-M) {
    padding: $container-padding-M;
  }

  @include breakpoint-min($breakpoint-L) {
    padding: $container-padding-L;
  }

  @include breakpoint-min($breakpoint-XL) {
    padding: $container-padding-XL;
  }
}
