@import "@wingspanhq/fe-component-library/dist/theme.scss";

.notifications {
  position: fixed;
  z-index: 1200;
  left: 50%;
  top: pxToRem(67);
  padding: pxToRem(15);
  transform: translateX(-50%);
  max-width: pxToRem(504);
  &.ignore {
    pointer-events: none;
  }

  width: 100%;

  @include breakpoint-min($breakpoint-S) {
    top: pxToRem(84);
  }

  .notification {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: pxToRem(70);
    margin-bottom: pxToRem(10);
    box-shadow: $shadow-M;
    border-radius: pxToRem(2);
    position: relative;
    padding: pxToRem(15) pxToRem(50);
    border-width: pxToRem(2);
    border-style: solid;

    @include breakpoint-min($breakpoint-S) {
      padding: pxToRem(22) pxToRem(60);
    }

    transition: all 300ms ease-in;

    &.success {
      background: #eff8f3;
      border-color: #2f9b6b;
      .statusIcon {
        color: #2f9b6b;
      }
    }

    &.info {
      background: #f2f8fd;
      border-color: #c3e1f5;

      .statusIcon {
        color: #2f9b6b;
      }
    }

    &.error {
      .statusIcon {
        color: #f5554b;
      }

      background-color: #fceeee;
      border-color: #f5554b;
    }

    .close {
      position: absolute;
      width: $space-M;
      height: $space-M;
      top: $space-M;
      right: $space-M;
      color: #6b727f;
      cursor: pointer;
    }

    .statusIcon {
      position: absolute;
      width: pxToRem(20);
      height: pxToRem(20);
      left: pxToRem(22);
      top: pxToRem(18);

      @include breakpoint-min($breakpoint-S) {
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .text {
      font-family: $font-centra;
      font-weight: $font-weight-medium;
      font-size: $font-size-16;
      line-height: $line-height-24;
      letter-spacing: 0.01em;
      color: #282d37;
      a {
        font-family: $font-centra;
        font-weight: $font-weight-medium;
        font-size: $font-size-16;
        line-height: $line-height-24;
        letter-spacing: 0.01em;
        color: #282d37;
        text-decoration: underline;
      }
    }
  }

  .appear {
    opacity: 0.01;
    transform: translateY(-100%);
  }

  .appear.appearActive {
    opacity: 1;
    transform: none;
  }

  .appear.appearDone {
    opacity: 1;
    transform: none;
  }

  .exit {
    opacity: 1;
    transform: none;
  }

  .exit.exitActive {
    opacity: 0.01;
    transform: translateY(100%);
    z-index: 998;
  }

  .exit.exitDone {
    z-index: 998;
    transform: translateY(100%);
    opacity: 0.01;
  }

  .enter {
    transform: translateY(-100%);
    opacity: 0.01;
  }

  .enter.enterActive {
    opacity: 1;
    transform: none;
  }

  .enter.enterDone {
    opacity: 1;
    transform: none;
  }
}
