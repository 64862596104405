@import "../../../../../../node_modules/@wingspanhq/fe-component-library/dist/theme";

.filingYear {
  font-size: $font-size-24;
}

.description {
  font-size: $font-size-16;
}

.learnMoreLink {
  text-decoration: underline;
}
