@import "@wingspanhq/fe-component-library/dist/theme";

.content {
  flex: 1;
}

.modal {
  @include breakpoint-min($breakpoint-S) {
    overflow-y: visible;
  }
}
