@import '../../../styles/theme.scss';

.container {
  display: block;
  width: 100%;
  padding-left: pxToRem(5);
}

.item {
  position: relative;
  border-left-color: $color-gray200;
  border-left-style: solid;
  border-left-width: pxToRem(2);
  padding-bottom: $space-M;

  &:last-child {
    border-left-color: transparent;
  }
}

.mark {
  position: absolute;
  left: -(pxToRem(5));
  z-index: 1;
  background-color: white;
  font-family: $font-centra;
  font-weight: $font-weight-book;
  font-size: $font-size-16;
  line-height: $line-height-20;
  color: $color-gray600;
}

.content {
  padding-left: pxToRem(13);
}
