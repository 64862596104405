@import "@wingspanhq/fe-component-library/dist/theme.scss";


.container {
  width: 100%
}

.selectedFile {
  border: 1px solid $color-gray300;
  border-radius: $space-S;
}

.selectedFileName {
  flex: 1;
}

.addLogoBtn {
  cursor: pointer;
}