@import '../../../styles/theme.scss';

.radioInputBorderOption {
  padding: $space-XL !important;
  border: pxToRem(1) solid $color-gray200;
  border-radius: $space-XS;

  &.selected {
    background-color: $color-gray50;
    border: pxToRem(1) solid $color-blue500;
  }

  &.disabled {
    opacity: 0.5;
  }
}

.radioGroupItem {
  padding: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: pointer;

  & + .radioGroupItem {
    margin: $space-M 0 0 0;
  }

  input[type='radio'] {
    display: none;
  }

  &.disabled {
    opacity: 0.5;
  }
}

.radioGroupIcon {
  width: pxToRem(22);
  min-width: pxToRem(22);
  height: pxToRem(22);
  border-radius: 50%;
  border: pxToRem(2) solid $color-gray400;
  position: relative;
  margin: 0 $space-M 0 0;
}

input:checked + .radioGroupIcon {
  border: pxToRem(2) solid $color-blue400;
  &:after {
    content: '';
    position: absolute;
    display: block;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: pxToRem(11);
    height: pxToRem(11);
    background-color: $color-blue400;
    border-radius: pxToRem(6);
  }
}

.radioGroup {
  display: flex;
  flex-direction: column;

  .item {
    margin-bottom: $space-M;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.label {
  flex: 1;
}
