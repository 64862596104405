@import "@wingspanhq/fe-component-library/dist/theme.scss";

.title {
  text-align: center;
  margin: pxToRem(10) pxToRem(20) 0 pxToRem(20);
  font-family: $font-centra;
  font-weight: $font-weight-medium;
  font-size: $font-size-18;
  line-height: $line-height-24;
  color: $color-gray700;

  @include breakpoint-min($breakpoint-S) {
    margin: pxToRem(38) 0 0 0;
    font-size: $font-size-24;
    line-height: $line-height-36;
  }
}

.description {
  margin: $space-M pxToRem(20) $space-XL pxToRem(20);
  font-family: $font-centra;
  font-size: $font-size-16;
  line-height: $line-height-24;
  letter-spacing: 0.01em;
  color: $color-gray500;
  text-align: center;

  @include breakpoint-min($breakpoint-S) {
    font-size: $font-size-12;
    line-height: $line-height-24;
  }
}

.additionalDescription {
  display: flex;
  max-width: pxToRem(640);
  margin-top: $space-M;

  p {
    font-size: $font-size-12;
    line-height: $line-height-24;
    margin: 0;
    letter-spacing: 0.01em;
    color: $color-gray500;
  }

  i {
    width: pxToRem(14);
    height: pxToRem(14);
    min-width: pxToRem(14);
    margin-right: pxToRem(6);
    color: $color-gray500;
  }
}

.text {
  font-size: $font-size-16;
  line-height: $line-height-24;
  letter-spacing: 0.01em;
}

.checkGroup {
  flex-direction: column;
  width: 100%;

  label {
    max-width: pxToRem(340);
    width: 100%;
    margin: 0 0 $space-M 0;
  }
}

.otherCheckbox {
  display: flex;
  align-items: center;
  flex: 1;

  .otherCheckboxTitle {
    font-size: $font-size-16;
    line-height: $line-height-24;
    color: $color-gray700;
    letter-spacing: 0.01em;
    margin: 0 $space-M 0 0;
    padding: 0;
  }

  .input {
    &.disabled {
      pointer-events: none;
    }

    width: 100%;
    height: pxToRem(50);
  }
}

.wrapper {
  padding: 0 $space-XL;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 pxToRem(20);
  width: 100%;
  max-width: pxToRem(506);
}

.row {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @include breakpoint-min($breakpoint-S) {
    flex-direction: row;
    justify-content: center;
    align-items: start;

    > * {
      flex: 1;
      margin-right: pxToRem(20);

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: pxToRem(20) 0 pxToRem(20) 0;

  button {
    min-width: pxToRem(136);
  }

  @include breakpoint-min($breakpoint-S) {
    margin: pxToRem(40) 0 0 0;
  }

  > * {
    margin-bottom: $space-M;
  }
}
