@import "@wingspanhq/fe-component-library/dist/theme.scss";

.container {
  display: flex;
  justify-content: space-between;

  &::after {
    content: "";
    display: block;
    position: absolute;
    height: pxToRem(1);
    top: $space-S;
    background-color: $color-gray400;
    width: 100%;
  }

  .status {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 0 $space-M;

    .dot {
      width: $space-L;
      height: $space-L;
      display: flex;
      align-items: center;
      justify-content: center;

      &::before {
        content: "";
        display: block;
        width: $space-S;
        height: $space-S;
        border-radius: 50%;
        background-color: $color-gray400;
      }
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}
