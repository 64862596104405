@import "../../styles/theme";

.status-default {
  border-color: $color-gray300;
  background-color: $color-white;
  &:focus-within {
    border-color: $color-blue400;
    box-shadow: 0 0 0 pxToRem(4) $color-blue100;
  }
}

.status-success {
  border-color: $color-green400;
  background-color: $color-green50;
  &:focus-within {
    box-shadow: 0 0 0 pxToRem(4) $color-green100;
  }
}

.status-warning {
  border-color: $color-amber400;
  background-color: $color-amber50;
  &:focus-within {
    box-shadow: 0 0 0 pxToRem(4) $color-amber100;
  }
}

.status-error {
  border-color: $color-red400;
  background-color: $color-red50;
  &:focus-within {
    box-shadow: 0 0 0 pxToRem(4) $color-red100;
  }
}
