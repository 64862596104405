@import "@wingspanhq/fe-component-library/dist/theme";

.section {
  margin-bottom: pxToRem(30);
  border-bottom: pxToRem(1) solid #e8eaef;
  padding-bottom: pxToRem(30);

  &:last-of-type {
    border-bottom: 0;
    padding-bottom: 0;
  }

  .sectionTitle {
    font-size: $font-size-16;
    line-height: $line-height-24;
    letter-spacing: -0pxtorem (3);
    margin-bottom: pxToRem(19);
  }

  ul {
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: pxToRem(30);
      cursor: pointer;

      &:last-child {
        margin-bottom: 0;
      }

      .name {
        font-weight: $font-weight-medium;
        font-size: $font-size-14;
        line-height: $line-height-16;
        color: #282d37;
        display: flex;
        align-items: center;
        padding-right: $space-M;
        > * {
          margin-right: $space-M;
        }
      }
    }
  }
}
