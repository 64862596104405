@import "../../../styles/theme.scss";
@import "../../../styles/helpers.scss";

.pill {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  height: $space-XL;
  padding: 0 $space-M;
  border-width: pxToRem(1);
  border-style: solid;
  border-radius: pxToRem(12);
  user-select: none;
  min-width: 0;
  max-width: 100%;
  max-width: -webkit-fill-available;
  gap: $space-XS;
}

.text {
  color: inherit;
  white-space: nowrap;
}

.truncated {
  .text {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

@mixin background_border_color($color) {
  background-color: $color;
  border-color: $color;
}

.theme-error--light {
  @include background_border_color($color-red50);
  color: $color-red400;
}

.theme-warning--light {
  @include background_border_color($color-amber50);
  color: $color-amber400;
}

.theme-success--light {
  @include background_border_color($color-green50);
  color: $color-green400;
}

.theme-neutral--light {
  @include background_border_color($color-gray100);
  color: $color-gray600;
}

.theme-blue--light {
  @include background_border_color($color-blue50);
  color: $color-blue500;
}

.theme-violet--light {
  @include background_border_color($color-violet50);
  color: $color-violet400;
}

@mixin theme_dark($color) {
  @include background_border_color($color);
  color: $color-white;
}

.theme-error--dark {
  @include theme_dark($color-red400);
}

.theme-warning--dark {
  @include theme_dark($color-amber300);
}

.theme-success--dark {
  @include theme_dark($color-green400);
}

.theme-neutral--dark {
  @include theme_dark($color-gray600);
}

.theme-blue--dark {
  @include theme_dark($color-blue500);
}

.theme-violet--dark {
  @include theme_dark($color-violet400);
}

@mixin theme_nobackground($color) {
  background-color: transparent;
  border-color: transparent;
  color: $color;
}

.theme-error--noBackground {
  @include theme_nobackground($color-red400);
}

.theme-warning--noBackground {
  @include theme_nobackground($color-amber400);
}

.theme-success--noBackground {
  @include theme_nobackground($color-green400);
}

.theme-neutral--noBackground {
  @include theme_nobackground($color-gray600);
}

.theme-blue--noBackground {
  @include theme_nobackground($color-blue500);
}

.theme-violet--noBackground {
  @include theme_nobackground($color-violet400);
}

@mixin theme_outline($color) {
  border-color: currentColor;
}

.theme-error--outline {
  @include theme_outline($color-red400);
}

.theme-warning--outline {
  @include theme_outline($color-amber400);
}

.theme-success--outline {
  @include theme_outline($color-green400);
}

.theme-neutral--outline {
  @include theme_outline($color-gray600);
}

.theme-blue--outline {
  @include theme_outline($color-blue500);
}

.theme-violet--outline {
  @include theme_outline($color-violet400);
}
