@import "@wingspanhq/fe-component-library/dist/theme.scss";

.loadingPlaceholder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  > img {
    width: 70%;
    max-width: pxToRem(300) !important;
    max-height: pxToRem(300) !important;
  }
}
