@import "@wingspanhq/fe-component-library/dist/theme";

.layout {
  min-height: 100vh;
}


.listItem {
  display: flex;
  border-color: $color-green400;
}