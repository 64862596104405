@import "@wingspanhq/fe-component-library/dist/theme.scss";

.base {
  .labelBase {
    margin-bottom: $space-S;
    display: flex;
    justify-content: space-between;
    font-family: $font-centra;
    font-weight: $font-weight-medium;
    font-size: $font-size-14;
    line-height: $line-height-20;
    letter-spacing: 0.01em;

    .label {
      color: $color-gray500;
    }

    .labelRight {
      color: #1b91e0;
    }
  }

  .input {
    background: white;
    border: pxToRem(1) solid $color-gray500;
    border-radius: pxToRem(4);
    font-family: $font-centra;
    font-size: $font-size-16;
    line-height: $line-height-20;
    padding: pxToRem(10);
    width: 100%;
    &:disabled {
      background-color: $color-gray50;
      cursor: no-drop;
      pointer-events: none;
    }
  }

  .error {
    margin: $space-S 0 0;
  }
}
