@use 'sass:map';
@import '../../styles/theme.scss';

//Base
.base {
  box-sizing: border-box;
}

// Reset

.reset {
  margin: 0;
}

// Spacing

$rulesM: 'margin';
$ruleM: (
  'name': 'M',
  'rules': $rulesM
);

$rulesMT: 'margin-top';
$ruleMT: (
  'name': 'MT',
  'rules': $rulesMT
);

$rulesMR: 'margin-right';
$ruleMR: (
  'name': 'MR',
  'rules': $rulesMR
);

$rulesMB: 'margin-bottom';
$ruleMB: (
  'name': 'MB',
  'rules': $rulesMB
);

$rulesML: 'margin-left';
$ruleML: (
  'name': 'ML',
  'rules': $rulesML
);

$rulesMX: 'margin-left', 'margin-right';
$ruleMX: (
  'name': 'MX',
  'rules': $rulesMX
);

$rulesMY: 'margin-top', 'margin-bottom';
$ruleMY: (
  'name': 'MY',
  'rules': $rulesMY
);

$rulesP: 'padding';
$ruleP: (
  'name': 'P',
  'rules': $rulesP
);

$rulesPT: 'padding-top';
$rulePT: (
  'name': 'PT',
  'rules': $rulesPT
);

$rulesPR: 'padding-right';
$rulePR: (
  'name': 'PR',
  'rules': $rulesPR
);

$rulesPB: 'padding-bottom';
$rulePB: (
  'name': 'PB',
  'rules': $rulesPB
);

$rulesPL: 'padding-left';
$rulePL: (
  'name': 'PL',
  'rules': $rulesPL
);

$rulesPX: 'padding-left', 'padding-right';
$rulePX: (
  'name': 'PX',
  'rules': $rulesPX
);

$rulesPY: 'padding-top', 'padding-bottom';
$rulePY: (
  'name': 'PY',
  'rules': $rulesPY
);

$rules: $ruleM, $ruleMT, $ruleMR, $ruleMB, $ruleML, $ruleMX, $ruleMY, $ruleP,
  $rulePT, $rulePR, $rulePB, $rulePL, $rulePX, $rulePY;

@mixin spaces {
  @each $rule in $rules {
    $ruleName: map.get($rule, 'name');
    $ruleRules: map.get($rule, 'rules');
    @each $spaceName, $spaceValue in $spaces {
      .space_#{$ruleName}_#{$spaceName} {
        @each $ruleRule in $ruleRules {
          #{$ruleRule}: #{$spaceValue};
        }
      }
    }
  }
}
@include spaces;

// Behavior

.clickable {
  cursor: pointer;
}

@keyframes shimmerAnimation {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100vw 0;
  }
}

@each $colorName, $colorValue in $colors {
  .bg-color--#{$colorName} {
    background-color: $colorValue;
  }
  .color-#{$colorName} {
    color: $colorValue;
  }
}

.shimmer {
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmerAnimation;
  animation-timing-function: linear;

  background: $color-gray200;
  background: linear-gradient(
    to right,
    $color-gray200 0%,
    $color-gray100 10%,
    $color-gray50 20%,
    $color-gray100 30%,
    $color-gray200 40%,
    $color-gray100 50%,
    $color-gray50 60%,
    $color-gray100 70%,
    $color-gray200 80%,
    $color-gray100 90%,
    $color-gray50 95%,
    $color-gray200 100%
  );
  background-size: 100vw 100%;

  color: transparent;

  > * {
    opacity: 0;
    pointer-events: none;
  }
}

// Shadows
@each $name, $value in $shadows {
  .shadow-#{$name} {
    box-shadow: $value;
  }
}
