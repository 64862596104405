@import "../../styles/theme.scss";

.loading {
  pointer-events: none;
}

.tableGroups {
  display: flex;
  flex-direction: column;
  gap: $space-XL;
}

.table {
  display: grid;
  max-width: 100%;
  align-items: center;
  border-radius: pxToRem(4);
  border: pxToRem(1) solid $color-gray200;
  overflow: hidden;
  background-color: $color-white;
}

.header {
  display: contents;
}

.headerCell {
  display: flex;
  align-items: center;
  padding: $space-M $space-L;
  background-color: $color-gray50;
  height: 100%;
  width: 100%;
  min-width: 0;

  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.groupHeader {
  display: contents;
}

.row {
  display: contents;
}

.cell {
  display: flex;
  align-items: center;
  border-top: pxToRem(1) solid $color-gray200;
  height: 100%;
  width: 100%;
  padding: $space-M $space-L;
  min-width: 0;
}

.row:first-child {
  .cell {
    border-top: 0;
  }
}

.rowWithHover:hover {
  .cell {
    background-color: $color-gray50;
  }
}

.row.selected {
  .cell {
    background-color: $color-blue50;
  }
}

.justify-start {
  justify-self: start;
  justify-content: flex-start;
}

.justify-end {
  justify-self: end;
  justify-content: flex-end;
  text-align: right;
}

.justify-center {
  justify-self: center;
  justify-content: center;
  text-align: center;
}

.justify-stretch {
  justify-self: stretch;
  justify-content: stretch;
}

.rowActions {
  display: flex;
  align-items: center;
  gap: $space-S;
}
