@import "@wingspanhq/fe-component-library/dist/theme.scss";

.icon {
  width: $space-2XL;
  min-width: $space-2XL;
  height: $space-2XL;
  position: relative;

  &::after {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    content: "💰";
    font-size: $font-size-24;
  }

  @include breakpoint-min($breakpoint-S) {
    width: $space-3XL;
    min-width: $space-3XL;
    height: $space-3XL;

    &::after {
      font-size: $font-size-30;
    }
  }
}

.content {
  flex: 1;
}

.panel {
}
