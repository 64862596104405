@import "@wingspanhq/fe-component-library/dist/theme.scss";

.point {
  flex: 0 0 pxToRem(24);
  width: pxToRem(24);
  height: pxToRem(24);
  border-radius: pxToRem(12);
  background-color: $color-gray300;
  border: solid pxToRem(5) $color-white;
  outline: solid pxToRem(4) $color-white;

  &.active {
    background-color: $color-gray600;
    border: solid pxToRem(5) $color-gray500;
  }

  &.loading,
  &.active.loading {
    background-color: $color-gray300;
    border: solid pxToRem(5) $color-white;
  }

  &.complete {
    color: $color-white;
    background-color: $color-gray500;
    border: solid pxToRem(5) $color-gray500;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}
