@import "../../../styles/theme.scss";

$size-XS: pxToRem(12);
$size-S: pxToRem(16);
$size-M: pxToRem(20);
$size-L: pxToRem(24);

.base {
  display: inline-block;
}

.svg {
  display: block;
  user-select: none;
  fill: currentColor;

  *[fill] {
    fill: currentColor;
  }
}

@mixin dimensions($size) {
  width: $size;
  height: $size;
  min-width: $size;
  min-height: $size;
  max-width: $size;
  max-height: $size;
}

.size {
  &-XS {
    @include dimensions($size-XS);
  }

  &-S {
    @include dimensions($size-S);
  }

  &-M {
    @include dimensions($size-M);
  }

  &-L {
    @include dimensions($size-L);
  }
}

.block {
  display: block;
}
