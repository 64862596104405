@import "../../styles/theme";

.textareaContainer {
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  padding: pxToRem(7) $space-L;
  border-style: solid;
  border-width: pxToRem(1);
  border-radius: pxToRem(2);
}

.textarea {
  font-family: $font-centra;
  font-style: normal;
  font-weight: $font-weight-book;
  font-size: $font-size-16;
  line-height: $line-height-24;
  color: $color-gray600;
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  resize: none;

  &:focus {
    border: none;
    outline: none;
  }

  &::placeholder {
    opacity: 1;
    color: $color-gray400;
  }
}

.disabled {
  pointer-events: none;
  background-color: $color-gray50;
  border-color: $color-gray200;

  textarea::placeholder {
    color: $color-gray200;
  }
}
