@import '../../../styles/theme';

.switch {
  display: inline-block;
  position: relative;
  cursor: pointer;

  width: pxToRem(36);
  height: pxToRem(20);
  border-radius: calc(#{pxToRem(20)} / 2);
  background-color: $color-gray500;
}

.onState {
  background-color: $color-blue400;
}

.input {
  display: none;
}

.input:checked + .switcher {
  transform: translate3d(125%, -50%, 0);
}

.switcher {
  position: absolute;
  left: 0;
  top: 50%;
  width: pxToRem(14);
  height: pxToRem(14);
  background-color: $color-white;
  border-radius: 50%;
  transform: translate3d(25%, -50%, 0);
  transition: transform 0.25s;
}
