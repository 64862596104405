@import "../../styles/theme.scss";

.controlWrapper {
  display: flex;
  flex-direction: column;
  gap: $space-L;
}

.control {
  display: flex;
  align-items: center;
  gap: $space-S;
  cursor: pointer;
}

.input {
  position: absolute;
  width: 0;
  height: 0;
  appearance: none;
}

.box {
  position: relative;
  flex-shrink: 0;
}

.input:focus ~ .box {
  outline: pxToRem(2) solid $color-gray200;
}

.input:checked:focus ~ .box {
  outline: pxToRem(2) solid $color-blue200;
}

.label {
  color: $color-gray500;
}

.helperText {
  color: $color-gray400;
}

.input:checked ~ .label {
  color: $color-gray700;
}

.disabled {
  cursor: not-allowed;

  .label {
    color: $color-gray300;
  }

  .input:checked ~ .label {
    color: $color-gray300;
  }

  .helperText {
    color: $color-gray300;
  }
}

.chip {
  border-radius: pxToRem(4);
  border: pxToRem(1) solid $color-gray300;
  background-color: white;
  padding: $space-XL;

  .tooltipIcon {
    margin-left: auto;
  }

  &.active {
    border-color: $color-blue400;
    background-color: $color-blue50;
    .label {
      color: $color-gray700;
    }
  }

  &.disabled {
    border-color: $color-gray200;
    background-color: white;

    .label {
      color: $color-gray300;
    }
  }
}

.checkbox,
.multistep-checkbox {
  .box {
    width: pxToRem(24);
    height: pxToRem(24);
    border-radius: pxToRem(4);
    border: pxToRem(2) solid $color-gray400;
  }

  .input:checked ~ .box {
    background-color: $color-blue400;
    border-color: $color-blue400;
  }

  .input:checked ~ .box::after {
    content: "";
    position: absolute;
    top: pxToRem(2);
    left: pxToRem(6);
    width: pxToRem(5);
    height: pxToRem(10);
    border: solid white;
    border-width: 0 pxToRem(2) pxToRem(2) 0;
    transform: rotate(45deg);
  }

  &.indeterminate {
    .input:checked ~ .box::after {
      transform: none;
      height: 0;
      width: pxToRem(8);
      border-width: pxToRem(1);
      border-radius: pxToRem(2);
      top: pxToRem(9);
      left: pxToRem(5);
    }
  }

  &.size-S {
    .box {
      width: pxToRem(16);
      height: pxToRem(16);
    }

    .input:checked ~ .box::after {
      top: pxToRem(2);
      left: pxToRem(4);
      width: pxToRem(3);
      height: pxToRem(6);
      border-width: 0 pxToRem(1) pxToRem(1) 0;
    }

    &.indeterminate {
      .input:checked ~ .box::after {
        transform: none;
        height: 0;
        width: pxToRem(4);
        border-width: pxToRem(1);
        border-radius: pxToRem(2);
        top: pxToRem(5);
        left: pxToRem(3);
      }
    }
  }

  &.disabled {
    .box {
      background-color: $color-gray50;
      border-color: $color-gray200;
    }

    .input:checked ~ .box {
      background-color: $color-gray200;
      border-color: $color-gray200;
    }
  }
}

.radio {
  .box {
    width: pxToRem(24);
    height: pxToRem(24);
    border-radius: 50%;
    border: pxToRem(2) solid $color-gray400;
  }

  .input:checked ~ .box {
    border-color: $color-blue400;
  }

  .input:checked ~ .box::after {
    content: "";
    position: absolute;
    top: pxToRem(3);
    left: pxToRem(3);
    width: pxToRem(14);
    height: pxToRem(14);
    border-radius: 50%;
    background-color: $color-blue400;
  }

  &.size-S {
    .box {
      width: pxToRem(16);
      height: pxToRem(16);
    }

    .input:checked ~ .box::after {
      top: pxToRem(2);
      left: pxToRem(2);
      width: pxToRem(8);
      height: pxToRem(8);
    }
  }

  &.disabled {
    .box {
      background-color: $color-gray50;
      border-color: $color-gray200;
    }

    .input:checked ~ .box {
      background-color: $color-gray50;
      border-color: $color-gray200;
    }

    .input:checked ~ .box::after {
      background-color: $color-gray200;
    }
  }
}

.switch {
  .box {
    width: pxToRem(40);
    height: pxToRem(24);
    border-radius: pxToRem(12);
    background-color: $color-gray400;
  }

  .box::after {
    content: "";
    position: absolute;
    top: pxToRem(2);
    left: pxToRem(2);
    width: pxToRem(20);
    height: pxToRem(20);
    border-radius: 50%;
    background-color: white;
    transition: left 0.2s;
  }

  .input:checked ~ .box {
    background-color: $color-blue400;
  }

  .input:checked ~ .box::after {
    left: pxToRem(18);
    box-shadow: $shadow-S;
  }

  &.size-S {
    .box {
      width: pxToRem(26);
      height: pxToRem(16);
    }

    .box::after {
      width: pxToRem(12);
      height: pxToRem(12);
    }

    .input:checked ~ .box::after {
      left: pxToRem(12);
    }
  }

  &.disabled {
    .box {
      background-color: $color-gray200;
    }

    .box::after {
      background-color: $color-gray50;
    }

    .input:checked ~ .box {
      background-color: $color-gray200;
    }
  }
}
