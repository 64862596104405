@import "@wingspanhq/fe-component-library/dist/theme.scss";

.faq {
  margin-top: $space-5XL;
}

@include breakpoint-min($breakpoint-M) {
  .faq {
    padding-left: $space-2XL;
    border-left: pxToRem(1) solid $color-gray200;
  }
}

.listStyle {
  list-style-type: decimal;
  padding-left: $space-M;
}
