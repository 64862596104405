@import "@wingspanhq/fe-component-library/dist/theme.scss";

.image {
  transition: background-image 1s ease-in-out;
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
  height: pxToRem(210);
}

//added preloading images
.image::after {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
  content: url("../../../assets/images/manual-account-preview/blank-check.svg")
    url("../../../assets/images/manual-account-preview/routing-check.svg")
    url("../../../assets/images/manual-account-preview/account-check.svg")
    url("../../../assets/images/manual-account-preview/success-check.svg");
}

.image[data-focus="init"] {
  background-image: url("../../../assets/images/manual-account-preview/blank-check.svg");
}

.image[data-focus="routing"] {
  background-image: url("../../../assets/images/manual-account-preview/routing-check.svg");
}

.image[data-focus="account"] {
  background-image: url("../../../assets/images/manual-account-preview/account-check.svg");
}

.image[data-focus="success"] {
  background-image: url("../../../assets/images/manual-account-preview/success-check.svg");
}
