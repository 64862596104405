@import "@wingspanhq/fe-component-library/dist/theme.scss";

.showScroll {
  overflow-x: scroll;
}

.rotate {
  transform: rotateX(180deg);
}

