@import "@wingspanhq/fe-component-library/dist/theme.scss";

.header {
  display: flex;
  align-items: center;
  height: pxToRem(72);

  @include breakpoint-min($breakpoint-S) {
    height: pxToRem(128);
  }

  .headerInner {
    display: flex;
    align-items: center;
    justify-content: center;

    .partnerLogo {
      .partnerLogoImage {
        max-width: pxToRem(129);
        max-height: pxToRem(48);

        @include breakpoint-min($breakpoint-S) {
          max-width: pxToRem(234);
          max-height: pxToRem(72);
        }
      }
    }
  }
}

.banner {
  background-color: #fff2e8;

  .bannerInner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $space-M;
    min-height: pxToRem(36);

    @include breakpoint-min($breakpoint-S) {
      min-height: $space-3XL;
    }
  }
}

.backButton {
  position: absolute;
  left: 0;
}
