@import "@wingspanhq/fe-component-library/dist/theme.scss";

.institutionLogo {
  .logoImage {
    &.S {
      height: pxToRem(24);
      width: pxToRem(24);
    }
    &.M {
      height: pxToRem(32);
      width: pxToRem(32);
    }
    &.L {
      height: pxToRem(40);
      width: pxToRem(40);
    }
  }
}
