@import "../../styles/theme";

.select {
  position: relative;
}

.manualInput {
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
}

.dropdown {
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 100;
  background: $color-white;
  border-radius: pxToRem(2);
  box-shadow: $shadow-S;
}

.select:focus-within .inputContainer {
  &[class*="status-default"] {
    border-color: $color-blue400;
    box-shadow: 0 0 0 pxToRem(4) $color-blue100;
  }

  &[class*="status-success"] {
    box-shadow: 0 0 0 pxToRem(4) $color-green100;
  }

  &[class*="status-warning"] {
    box-shadow: 0 0 0 pxToRem(4) $color-amber100;
  }

  &[class*="status-error"] {
    box-shadow: 0 0 0 pxToRem(4) $color-red100;
  }
}

.disabled .inputContainer {
  & > * {
    opacity: 0.5;
  }
}

.search {
  display: flex;
  gap: $space-S;
  padding: $space-M;
  margin-top: $space-L;
  border-top: pxToRem(1) solid $color-gray200;
  border-bottom: pxToRem(1) solid $color-gray200;

  input {
    width: 100%;
    border: 0;
    outline: 0;
    font-family: $font-centra;
    font-size: $font-size-12;
    line-height: $line-height-16;
    font-weight: $font-weight-book;
  }
}

.options {
  padding: $space-S 0;
  overflow-y: auto;
  max-height: pxToRem(296);
}

.option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $space-S;
  padding: $space-M;

  &:hover {
    background: $color-gray50;
  }

  &.active {
    background: $color-blue50;
  }
}

.optionContent {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $space-S;
}

.optionLabel {
  flex: 1;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: pxToRem(1) solid $color-gray200;
  gap: $space-S;
  padding: $space-M;

  button div {
    white-space: nowrap;
  }
}

.sidebarContent {
  .wrapper {
    .emptyState {
      height: pxToRem(420);
      max-height: 50vh;
    }
  }
}
