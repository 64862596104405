@import '../../../styles/theme.scss';

.clear {
  cursor: pointer;
  border: solid pxToRem(1) $color-gray200;
  border-radius: pxToRem(4);
  background-color: $color-white;
  height: pxToRem(44);
  display: flex;
  align-items: center;
  padding: 0 $space-L;
}

.clearIcon {
  margin-left: auto;
}

.filtersContainer {
}

.filters {
}
.filterIcon {
  margin-left: auto;
}

.actions {
}

.filter {
  cursor: pointer;
  border: solid pxToRem(1) $color-gray200;
  border-radius: $space-XS;
  background-color: $color-white;
  height: pxToRem(44);
  display: flex;
  align-items: center;
  padding: 0 $space-L;
}

.filterCount {
  border-radius: pxToRem(2);
  background-color: $color-gray700;
  height: $space-L;
  width: $space-L;
  min-width: $space-L;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filtersContent {
}

.search {
  border: solid pxToRem(1) $color-gray200;
  border-radius: $space-XS;
  background-color: $color-white;
  i {
    min-width: $space-L;
    min-height: $space-L;
    margin-right: $space-S;
    color: $color-gray500;
  }
}
