@import "@wingspanhq/fe-component-library/dist/theme.scss";

.mobileHeader {
  display: flex;
  .companyName {
    flex: 1;
  }
  .logoAndLink {
    flex: 0;
  }
}

.companyLogo {
  max-width: $space-5XL;
  max-height: pxToRem(50);

  img {
    display: block;
    width: 100%;
  }
}

.mobileDrawer {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  width: 90%;
  background-color: white;
  overflow-y: auto;
  padding: $space-3XL $space-XL;
  box-shadow: $shadow-M;

  .closeIcon {
    position: absolute;
    padding: $space-M;
    top: 0;
    right: 0;
    z-index: 1000;

    i {
      display: block;
    }
  }
}
