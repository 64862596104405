@import "../../../styles/theme.scss";

.error {
  display: flex;
  align-items: center;
  margin-top: $space-S;

  .errorText {
    color: $color-red400;
  }

  .errorIcon {
    margin-right: $space-S;
    color: $color-red400;
  }
}
