@import "../../../styles/theme.scss";
@import "../../../styles/helpers";

$breakpoint-mobile-320: pxToRem(320);

.container {
  display: flex;
  border-radius: $space-XS;
  overflow: hidden;
  .body {
    border-radius: inherit;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding: $space-M;
    border: pxToRem(1) solid $color-gray400;
    border-right: none;
    overflow: hidden;
    flex: 1;

    .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      direction: rtl;
      text-align: left;
      width: pxToRem(208);

      @include breakpoint-min($breakpoint-S) {
        width: 100%;
      }
    }
  }

  .copyBtn {
    border-radius: 0;
    white-space: nowrap;
    i {
      margin-right: 0;
    }
  }
}
