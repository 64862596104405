@use "sass:math";
@import "../../../styles/theme.scss";

$columns: 12;

$gutter: (
  "none": 0,
  "XS": $space-XS,
  "S": $space-S,
  "M": $space-M,
  "L": $space-L,
  "XL": $space-XL,
  "2XL": $space-2XL,
  "3XL": $space-3XL
);

.grid {
  display: grid;
  grid-template-columns: repeat($columns, 1fr);
  gap: $space-L;
  max-width: 100%;
}

.item {
  grid-column: span 12;
}

@mixin common($breakpointName: null) {
  $breakpointSuffix: if($breakpointName, "-bp-" + $breakpointName, "");

  @each $gutterName, $gutterValue in $gutter {
    .gutter-#{$gutterName}#{$breakpointSuffix} {
      gap: $gutterValue;
    }
  }

  .span-0#{$breakpointSuffix} {
    display: none;
  }

  @for $i from 1 through $columns {
    .span-#{$i}#{$breakpointSuffix} {
      display: block;
      grid-column: span $i;
    }

    @if $i % 2 == 0 {
      .span-centered-#{$i}#{$breakpointSuffix} {
        $offset: math.div(12 - $i, 2);

        grid-column: #{$offset + 1} / #{$i + $offset + 1};
      }
    }
  }
}

@include common();

@each $breakpointName, $breakpointValue in $breakpoints {
  @include breakpoint-min($breakpointValue) {
    @include common($breakpointName);
  }
}
