@import "@wingspanhq/fe-component-library/dist/theme.scss";

.formContainer {
  border: pxToRem(1) solid $color-gray200;
  border-radius: pxToRem(6);

  .input {
    background-color: transparent;
    border-radius: pxToRem(4);
    border: pxToRem(1) solid $color-gray600;
  }

  .tryWSBtn {
    border-radius: pxToRem(6);
  }

  .link {
    color: $color-gray600;
    text-decoration: underline;
  }

  .emailPreview {
    padding: $space-M;
    border: $color-gray400 solid pxToRem(1);
    border-radius: pxToRem(4);
  }

  .userIcon {
    border: $color-gray200 solid pxToRem(1);
    background-color: $color-white;
    border-radius: $space-L;
    width: $space-2XL;
    min-width: $space-2XL;
    height: $space-2XL;
  }

  .userDetails {
    overflow: hidden;
  }
}
