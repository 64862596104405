@import "@wingspanhq/fe-component-library/dist/theme.scss";

.accountsList {
  width: 100%;
}

.account {
  padding: $space-M 0;
  min-height: pxToRem(52);
}

.accountsGroupHeader {
  flex: 1;
  min-width: 0;
}
