@import "@wingspanhq/fe-component-library/dist/theme.scss";

.logoContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;

  img {
    max-height: calc(100% - pxToRem(10));
  }
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: pxToRem(44);
  border-bottom: pxToRem(2) solid $color-gray200;

  padding: 0 pxToRem(15);
  @include breakpoint-min($breakpoint-S) {
    padding: 0 pxToRem(26);
    height: pxToRem(68);
    background-size: auto pxToRem(22);
  }
}

.back {
  width: pxToRem(13);
  height: pxToRem(13);
  min-width: pxToRem(13);
  color: $color-gray500;
  cursor: pointer;

  @include breakpoint-min($breakpoint-S) {
    width: $space-XL;
    height: $space-XL;
  }
}

.progress {
  display: block;
  width: 100%;
  position: absolute;
  left: 0;
  height: pxToRem(2);
  background-color: $color-garnet;
  bottom: pxToRem(-2);
  z-index: 1;
}
