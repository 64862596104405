@import "../../styles/theme.scss";

.alert {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: stretch;

  border-radius: pxToRem(4);
  border: pxToRem(1) solid $color-gray50;
  background: $color-gray50;
  overflow: hidden;
  width: 100%;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    padding-right: pxToRem(8);
  }
}

.closeWrapper {
  margin-left: $space-M;
}

.iconWrapper {
  margin-right: $space-M;
}

.content {
  flex: 1;
}

.action {
  padding-left: 0;
}

.type-neutral {
  border-color: $color-blue400;
  background-color: $color-blue50;

  &:before {
    background-color: $color-blue500;
  }

  .icon, .close {
    color: $color-blue500;
  }

  .title {
    color: $color-blue500;
  }
}

.type-info {
  border-color: $color-gray400;
  background-color: $color-gray50;

  &:before {
    background-color: $color-gray600;
  }

  .icon, .close {
    color: $color-gray600;
  }

  .title {
    color: $color-gray700;
  }
}

.type-warning {
  border-color: $color-amber300;
  background-color: $color-amber50;

  &:before {
    background-color: $color-amber400;
  }

  .icon, .close {
    color: $color-amber400;
  }

  .title {
    color: $color-amber400;
  }
}

.type-success {
  border-color: $color-green300;
  background-color: $color-green50;

  &:before {
    background-color: $color-green400;
  }

  .icon, .close {
    color: $color-green400;
  }

  .title {
    color: $color-green400;
  }
}

.type-error {
  border-color: $color-red300;
  background-color: $color-red50;

  &:before {
    background-color: $color-red400;
  }

  .icon, .close {
    color: $color-red400;
  }

  .title {
    color: $color-red400;
  }
}

.size-S {
  padding: $space-S $space-S $space-S $space-XL;
}

.size-M {
  padding: $space-M $space-M $space-M $space-XL;
}
