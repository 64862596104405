@import "@wingspanhq/fe-component-library/dist/theme.scss";

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: pxToRem(20);
  height: pxToRem(20);
  flex: 0 0 pxToRem(20);
}
