@import "@wingspanhq/fe-component-library/dist/theme.scss";

.main {
  border-radius: pxToRem(10);
  border: pxToRem(1) solid $color-gray200;
  overflow: hidden;
}

.settingsContainer {
  border-top: pxToRem(1) solid $color-gray200;
  background-color: $color-gray50;
}