@import "../../../styles/theme.scss";

.activeItem {
  background-color: $color-gray50;
  border-color: $color-blue300;
}

.previewContainer {
  background-color: $color-gray50;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $space-XL;
  box-sizing: content-box;

  .imageContainer {
    flex: 1;
    display: flex;
    max-width: 75%;
    min-height: pxToRem(320);
    align-items: center;
    justify-content: center;
  }
}
