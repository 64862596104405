@import '../../../styles/theme.scss';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.plus,
.minus {
  width: pxToRem(44);
  min-height: pxToRem(44);
  min-width: pxToRem(44);
  height: pxToRem(44);

  border: pxToRem(1) solid $color-gray500;
  border-radius: $space-4XL;
  position: relative;

  &:before {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &:focus {
    outline: none;
  }
}

.plus:before {
  content: '+';
}

.minus:before {
  content: '-';
}

.value {
}
