@import "@wingspanhq/fe-component-library/dist/theme.scss";

.defaultLogo,
.logo {
  width: $space-2XL;
  height: $space-2XL;
  min-width: $space-2XL;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: $space-L;
  background-size: cover;
  overflow: hidden;
  display: inline-block;
}

.logo {
  background-color: white;
}

.defaultLogo {
  position: relative;
  background-color: $color-gray200;
  i {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: $space-L;
    height: $space-L;
    color: $color-gray500;
  }
}
