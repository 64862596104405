@import "@wingspanhq/fe-component-library/dist/theme";
@import "index.module";


.cardPlaceholder {
  @extend .cardBasic;

  &.virtualCard {
    background-image: url("../../../../assets/images/wallet/virtualCardBackground.svg");
  }

  &.physicalCard {
    background-image: url("../../../../assets/images/wallet/physicalCardBackground.svg");
  }


  .overlay {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .button {
    border-color: white;

    &:hover {
      background-color: transparent;
      border-color: $color-gray300;
    }
  }

  .buttonText {
    color: white;

    &:hover {
      color: $color-gray300;
    }
  }

}