@import "../../styles/theme";

.inputContainer {
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  padding: pxToRem(7) $space-L;
  border-style: solid;
  min-width: 0;

  input {
    ::placeholder {
      opacity: 1;
      color: $color-gray400;
    }
  }
}

.input {
  flex: 1;
  min-width: 0;
}

.input input {
  font-family: $font-centra;
  font-style: normal;
  font-weight: $font-weight-book;
  font-size: $font-size-16;
  line-height: $line-height-24;
  color: $color-gray600;
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;

  &:focus {
    border: none;
    outline: none;
  }

  &::placeholder {
    color: $color-gray400;
  }
}

.disabled {
  pointer-events: none;
  background-color: $color-gray50;
  border-color: $color-gray200;

  .icon *,
  .iconRight *,
  .actionButton *,
  .input input {
    color: $color-gray300;
    pointer-events: none;
  }

  input::placeholder {
    color: $color-gray200;
  }
}

.actionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: pxToRem(16);
  height: pxToRem(16);
  background-color: transparent;
  margin: 0 0 0 $space-M;
  padding: 0;
  border: none;
  cursor: pointer;
  user-select: none;
  line-height: 0;

  &:active,
  &:focus {
    background-color: transparent;
  }

  i {
    color: $color-gray600;
  }
}

.icon {
  margin-right: $space-M;
  color: $color-gray500;
}

.iconRight {
  color: $color-gray500;
  margin-left: $space-M;
}

.size-M {
  border-width: pxToRem(1);
  height: pxToRem(40);
  border-radius: pxToRem(2);
  .input input {
    font-size: $font-size-16;
    line-height: pxToRem(24);
  }
}

.size-L {
  border-width: pxToRem(2);
  height: pxToRem(76);
  border-radius: pxToRem(16);
  .input input {
    font-size: $font-size-48;
    line-height: pxToRem(60);
  }
}

.align-left {
  input {
    text-align: left;
  }
}

.align-right {
  input {
    text-align: right;
  }
}
