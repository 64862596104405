@import "@wingspanhq/fe-component-library/dist/theme.scss";

.csvLink {
  width: 100%;
}

.nextBtn {
  width: 100%;
}

.panel {
  border-radius: $space-S;
}

.elem {
  border-radius: $space-XS;
}
