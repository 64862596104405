@import "../../../styles/theme.scss";

@keyframes loaderAnimation {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

.button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.loading {
    position: relative;
    cursor: not-allowed;

    .label,
    .iconLeft,
    .iconRight {
      opacity: 0;
    }

    &::after {
      display: block;
      position: absolute;
      content: "";
      top: 50%;
      left: 50%;
      border-width: pxToRem(2);
      border-style: solid;
      border-color: currentColor currentColor currentColor transparent;
      border-radius: 50%;
      width: 1em;
      height: 1em;
      -webkit-animation: loaderAnimation 2s linear infinite; /* Safari */
      animation: loaderAnimation 2s linear infinite;
    }

    &.Link {
     background-color: transparent;
    }
  }
}
.iconLeftOnly {
  margin: 0;
}

.iconLeft {
  margin-right: $space-S;
}

.iconRight {
  margin-left: $space-S;
}

.fullWidth {
  display: flex;
  width: 100%;
}

.label {
  color: inherit;
  font-weight: $font-weight-medium;
}

.disabled {
  cursor: not-allowed;
}

.buttonSize-S {
  padding: $space-S $space-L;
  font-size: $font-size-12;
  line-height: $line-height-16;

  .label {
    font-size: $font-size-12;
    line-height: $line-height-16;
    min-height: $line-height-16;
  }
}

.buttonSize-M {
  padding: $space-S $space-L;
  font-size: $font-size-14;
  line-height: $line-height-20;

  .label {
    font-size: $font-size-14;
    line-height: $line-height-20;
    min-height: $line-height-20;
  }
}

.buttonSize-L {
  padding: $space-M $space-XL;
  font-size: $font-size-16;
  line-height: $line-height-24;

  .label {
    font-size: $font-size-16;
    line-height: $line-height-24;
    min-height: $line-height-24;
  }
}

.buttonSize-XL {
  padding: $space-M $space-XL;
  font-size: $font-size-18;
  line-height: $line-height-28;

  .label {
    font-size: $font-size-18;
    line-height: $line-height-28;
    min-height: $line-height-28;
  }
}

@mixin buttonBase {
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
}

.Primary {
  @include buttonBase;
  background-color: $color-blue400;
  border-color: $color-blue400;
  color: $color-white;

  &:hover {
    background-color: $color-blue500;
    border-color: $color-blue500;
  }

  &:focus {
    background-color: $color-blue500;
    border-color: $color-blue500;
    outline: 4px solid $color-blue100;
  }

  &:active {
    background-color: $color-blue600;
    border-color: $color-blue600;
  }

  &.disabled {
    background-color: $color-gray300;
    border-color: $color-gray300;
  }
}

.Primary--destructive {
  @include buttonBase;
  background-color: $color-red300;
  border-color: $color-red300;
  color: $color-white;

  &:hover {
    background-color: $color-red400;
    border-color: $color-red400;
  }

  &:focus {
    background-color: $color-red400;
    border-color: $color-red400;
    outline: 4px solid $color-red100;
  }

  &:active {
    background-color: $color-red500;
    border-color: $color-red500;
  }

  &.disabled {
    background-color: $color-gray300;
    border-color: $color-gray300;
  }
}

.Secondary {
  @include buttonBase;
  background-color: transparent;
  border-color: $color-blue400;
  color: $color-blue400;

  &:hover {
    background-color: $color-blue50;
    border-color: $color-blue500;
    color: $color-blue500;
  }

  &:focus {
    background-color: $color-blue50;
    border-color: $color-blue500;
    color: $color-blue500;
    outline: 4px solid $color-blue100;
  }

  &:active {
    background-color: $color-blue100;
    border-color: $color-blue600;
    color: $color-blue600;
  }

  &.disabled {
    border-color: $color-gray300;
    color: $color-gray300;
  }
}

.Secondary--destructive {
  @include buttonBase;
  background-color: transparent;
  border-color: $color-red300;
  color: $color-red300;

  &:hover {
    background-color: $color-red50;
    border-color: $color-red400;
    color: $color-red400;
  }

  &:focus {
    background-color: $color-red50;
    border-color: $color-red400;
    color: $color-red400;
    outline: 4px solid $color-red100;
  }

  &:active {
    background-color: $color-red100;
    border-color: $color-red500;
    color: $color-red500;
  }

  &.disabled {
    border-color: $color-gray300;
    color: $color-gray300;
  }
}

.Tertiary {
  @include buttonBase;
  background-color: transparent;
  border-color: $color-gray500;
  color: $color-gray600;

  &:hover {
    background-color: $color-gray50;
    border-color: $color-gray600;
  }

  &:focus {
    background-color: $color-gray50;
    border-color: $color-gray600;
    outline: 4px solid $color-gray200;
  }

  &:active {
    background-color: $color-gray200;
    border-color: $color-gray700;
    color: $color-gray700;
  }

  &.disabled {
    border-color: $color-gray300;
    color: $color-gray300;
  }
}

.Link {
  background-color: transparent;
  border: none;
  padding: $space-XS;
  color: $color-blue400;

  &:hover {
    color: $color-blue500;

    .label {
      text-decoration: underline;
    }
  }

  &:focus {
    background-color: $color-blue50;
    color: $color-blue500;

    .label {
      text-decoration: underline;
    }
  }

  &:active {
    color: $color-blue600;

    .label {
      text-decoration: underline;
    }
  }

  &.disabled {
    border-color: $color-gray300;
    color: $color-gray300;
  }
}
