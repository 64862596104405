@import "@wingspanhq/fe-component-library/dist/theme";

.trigger {
  cursor: pointer;
}

.membership {
  width: 100%;
  flex: 1;
  border: pxToRem(1) solid $color-gray200;
  position: relative;
  background-color: $color-white;
  z-index: 2;

  &.error {
    border-color: $color-red500;
    background-color: $color-red50;
  }
}

.options {
  position: absolute;
  width: 100%;
  z-index: 1;
  display: none;
}

.margin {
  pointer-events: none;
}

.trigger:focus + .options {
  display: block;
}

.option {
  &:hover {
    background-color: $color-gray50;
  }
}
