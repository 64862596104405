@import "@wingspanhq/fe-component-library/dist/theme.scss";

.higherPointsList {
  li {
    font-size: 0.875rem;
    margin-bottom: 0 !important;
  }
}

.lowerAlphaList {
  list-style-type: lower-alpha;

  li {
    font-size: 0.875rem;
    margin-bottom: 0 !important;
  }
}