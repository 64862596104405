@import "@wingspanhq/fe-component-library/dist/theme.scss";

.actions {
  width: 100%;
  @include breakpoint-min($breakpoint-S) {
    width: auto;
  }
}

.panels {
  width: 100%;

  > * {
    width: 100%;
  }
}

.panel {
  overflow: auto;
}