@import "../../styles/theme";

.sidebarContent {
  padding-top: $space-3XL;
}

:global {
  .p-tooltip {
    max-width: pxToRem(300);
    position: absolute;

    .p-tooltip-text {
      border-radius: pxToRem(4);
      box-sizing: border-box;
      padding: $space-L;
      background-color: $color-white;
      box-shadow: $shadow-L;
      font-family: $font-centra;
      font-size: $font-size-14;
      line-height: $line-height-20;
      font-weight: $font-weight-book;
      color: $color-gray600;

      &-dark {
        background-color: $color-gray700;
        color: $color-white;
      }
    }
  }
}
