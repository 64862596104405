@import "../../styles/theme";

.mainRow {
}

.title {
  min-width: 0;
}

.secondaryRow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: $space-M;
  flex-wrap: wrap;
  min-width: 0;

  @include breakpoint-min($breakpoint-S) {
    flex-wrap: nowrap;
  }
}

.titleInfo {
  min-width: 0;

  > * {
    min-width: 0;
  }
}

.newRowOnMobile {
 flex-basis: 100%;
}

.pillTitle {
  flex: none;
}
