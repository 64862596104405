@import "./helpers.scss";

// -------------------------------------------------------
// Typography
// -------------------------------------------------------

// fonts
$font-centra: "Centra No2", sans-serif;

// font sizes
$font-size-60: pxToRem(60);
$font-size-48: pxToRem(48);
$font-size-36: pxToRem(36);
$font-size-30: pxToRem(30);
$font-size-24: pxToRem(24);
$font-size-20: pxToRem(20);
$font-size-18: pxToRem(18);
$font-size-16: pxToRem(16);
$font-size-14: pxToRem(14);
$font-size-12: pxToRem(12);
$font-size-10: pxToRem(10);

// font weights
$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-book: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

// line heights
$line-height-64: pxToRem(64);
$line-height-60: pxToRem(60);
$line-height-48: pxToRem(48);
$line-height-40: pxToRem(40);
$line-height-36: pxToRem(36);
$line-height-32: pxToRem(32);
$line-height-28: pxToRem(28);
$line-height-24: pxToRem(24);
$line-height-20: pxToRem(20);
$line-height-16: pxToRem(16);

// -------------------------------------------------------
// Colors
// -------------------------------------------------------

// Brand
$color-garnet: #960145;
$color-black: #090706;
$color-cream: #f7f7f7;

// Neutral
$color-gray700: #111827;
$color-gray600: #374151;
$color-gray500: #6b7280;
$color-gray400: #9ca3af;
$color-gray300: #d1d5db;
$color-gray200: #e5e7eb;
$color-gray100: #f3f4f6;
$color-gray50: #f9fafb;
$color-white: white;

// Primary
$color-blue600: #0c4a6e;
$color-blue500: #0369a1;
$color-blue400: #0284c7;
$color-blue300: #0ea5e9;
$color-blue200: #7dd3fc;
$color-blue100: #bae6fd;
$color-blue50: #f0f9ff;

// Functional
$color-green500: #064e3b;
$color-green400: #047857;
$color-green300: #10b981;
$color-green200: #6ee7b7;
$color-green100: #a7f3d0;
$color-green50: #ecfdf5;

$color-red500: #7f1d1d;
$color-red400: #b91c1c;
$color-red300: #ef4444;
$color-red200: #fca5a5;
$color-red100: #fecaca;
$color-red50: #fef2f2;

$color-amber500: #92400e;
$color-amber400: #b45309;
$color-amber300: #f59e0b;
$color-amber200: #fcd34d;
$color-amber100: #fde68a;
$color-amber50: #fffbeb;

// Tertiary
$color-violet500: #4c1d95;
$color-violet400: #6d28d9;
$color-violet300: #8b5cf6;
$color-violet200: #c4b5fd;
$color-violet100: #ddd6fe;
$color-violet50: #f5f3ff;

$color-pink500: #9d174d;
$color-pink400: #be185d;
$color-pink300: #ec4899;
$color-pink200: #f9a8d4;
$color-pink100: #fbcfe8;
$color-pink50: #fdf2f8;

// Colors map

$colors: (
  "garnet": #960145,
  "black": #090706,
  "cream": #f7f7f7,
  "gray700": #111827,
  "gray600": #374151,
  "gray500": #6b7280,
  "gray400": #9ca3af,
  "gray300": #d1d5db,
  "gray200": #e5e7eb,
  "gray100": #f3f4f6,
  "gray50": #f9fafb,
  "white": white,
  "blue600": #0c4a6e,
  "blue500": #0369a1,
  "blue400": #0284c7,
  "blue300": #0ea5e9,
  "blue200": #7dd3fc,
  "blue100": #bae6fd,
  "blue50": #f0f9ff,
  "green500": #064e3b,
  "green400": #047857,
  "green300": #10b981,
  "green200": #6ee7b7,
  "green100": #a7f3d0,
  "green50": #ecfdf5,
  "red500": #7f1d1d,
  "red400": #b91c1c,
  "red300": #ef4444,
  "red200": #fca5a5,
  "red100": #fecaca,
  "red50": #fef2f2,
  "amber500": #92400e,
  "amber400": #b45309,
  "amber300": #f59e0b,
  "amber200": #fcd34d,
  "amber100": #fde68a,
  "amber50": #fffbeb,
  "violet500": #4c1d95,
  "violet400": #6d28d9,
  "violet300": #8b5cf6,
  "violet200": #c4b5fd,
  "violet100": #ddd6fe,
  "violet50": #f5f3ff,
  "pink500": #9d174d,
  "pink400": #be185d,
  "pink300": #ec4899,
  "pink200": #f9a8d4,
  "pink100": #fbcfe8,
  "pink50": #fdf2f8,

  "transparent": transparent
);

// -------------------------------------------------------
// Spacing
// -------------------------------------------------------

$space-XS: pxToRem(4);
$space-S: pxToRem(8);
$space-M: pxToRem(12);
$space-L: pxToRem(16);
$space-XL: pxToRem(24);
$space-2XL: pxToRem(32);
$space-3XL: pxToRem(48);
$space-4XL: pxToRem(64);
$space-5XL: pxToRem(80);
$space-6XL: pxToRem(96);

$spaces: (
  "XS": $space-XS,
  "S": $space-S,
  "M": $space-M,
  "L": $space-L,
  "XL": $space-XL,
  "2XL": $space-2XL,
  "3XL": $space-3XL,
  "4XL": $space-4XL,
  "5XL": $space-5XL,
  "6XL": $space-6XL
);

$extended-spaces: map-merge($spaces, ("NONE": 0));

// -------------------------------------------------------
// Breakpoints
// -------------------------------------------------------

$breakpoint-S: 768px;
$breakpoint-M: 992px;
$breakpoint-L: 1280px;
$breakpoint-XL: 1520px;

$breakpoints: (
  "S": $breakpoint-S,
  "M": $breakpoint-M,
  "L": $breakpoint-L,
  "XL": $breakpoint-XL
);

// -------------------------------------------------------
// Effects
// -------------------------------------------------------

// Shadows
$shadow-inner: inset 0 pxToRem(2) pxToRem(4) rgba(0, 0, 0, 0.1);
$shadow-S: 0 pxToRem(2) pxToRem(4) rgba(0, 0, 0, 0.1);
$shadow-M: 0 pxToRem(4) pxToRem(8) rgba(0, 0, 0, 0.1);
$shadow-L: 0 pxToRem(8) pxToRem(12) rgba(0, 0, 0, 0.1);
$shadow-XL: 0 pxToRem(12) pxToRem(24) rgba(0, 0, 0, 0.1);
$shadow-2XL: 0 pxToRem(16) pxToRem(32) rgba(0, 0, 0, 0.5);

$shadows: (
  "inner": $shadow-inner,
  "S": $shadow-S,
  "M": $shadow-M,
  "L": $shadow-L,
  "XL": $shadow-XL,
  "2XL": $shadow-2XL
);

// -------------------------------------------------------
// Overlay
// -------------------------------------------------------

$overlay-color: rgba(17, 24, 39, 0.7);
$overlay-zIndex: 1100;
