@import "@wingspanhq/fe-component-library/dist/theme.scss";

.modal {
  min-height: pxToRem(576);
  padding: 0;
}

.contentWrapper {
  overflow-x: hidden;
  padding: $space-M;
}

.modalClose {
  z-index: 10;
  display: block;
  padding: $space-XS;
  position: absolute;
  top: pxToRem(14);
  right: pxToRem(14);
}

.search {
  border: none;
  padding-left: 0;
  background-color: transparent;
  &:focus,
  &:focus-within {
    outline: none;
    border: none;
  }

  > i {
    width: pxToRem(14);
    min-width: pxToRem(14);
    height: pxToRem(14);
  }
}

.searchInput {
  margin-left: $space-XS;
}

.category {
  height: $space-3XL;
  border-bottom: pxToRem(1) solid $color-gray200;
}

.categoryBody {
  flex: 1;
  min-width: 0;
}

.title,
.categoryName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.searchItem:not(:hover) {
  @include breakpoint-min($breakpoint-M) {
    .description {
      display: block;
      display: -webkit-box;
      max-width: pxToRem(480);
      max-height: 4em;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
