@use "sass:math";

$appFontSize: 16;

@function pxToRem($px, $fontSize: $appFontSize) {
  @if $px == 0 {
    @return 0rem;
  }
  @return #{math.div($px, $fontSize)}rem;
}

@mixin breakpoint-min($min) {
  @media (min-width: $min) {
    @content;
  }
}
