@import "@wingspanhq/fe-component-library/dist/theme.scss";

.base {
  margin: pxToRem(20) 0;
  color: $color-garnet;
  font-family: $font-centra;
  font-weight: $font-weight-medium;
  font-size: $font-size-14;
  line-height: $line-height-20;
  letter-spacing: 0.01em;
}
