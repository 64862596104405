@import "@wingspanhq/fe-component-library/dist/theme.scss";

.dateRange {
  label {
    min-width: pxToRem(240);
  }
}

.filtersWrapper {
  width: 100%;
  align-items: start;
}

.filters {
  flex: 1;
  max-width: calc(100% - pxToRem(30));
  min-width: calc(100% - pxToRem(30));
}

.csvLink {
  position: absolute;
  right: 0;
  z-index: 100;
  padding-bottom: 0;
  top: pxToRem(-40);
}
