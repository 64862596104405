@import "@wingspanhq/fe-component-library/dist/theme.scss";

.button {
  width: pxToRem(100);
  height: pxToRem(24);
  font-size: $font-size-12;
  font-family: $font-centra;
  color: $color-blue400;
  border: pxToRem(1) solid;
  border-radius: pxToRem(12);

  &:hover {
    color: $color-blue500;
  }

  &.default {
    background-color: $color-blue400;
    color: $color-white;
    border-color: $color-blue400;

    &:hover {
      color: $color-white;
      border-color: $color-blue400;
    }
  }
}

.bannerPanel {
  &.warning {
    background-color: $color-amber50;
  }

  &.error {
    background-color: $color-red50;
  }
}
