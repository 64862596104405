@import "@wingspanhq/fe-component-library/dist/theme.scss";

.container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;

  .video {
    background-color: $color-gray200;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
