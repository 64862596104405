@import "../../../styles/theme.scss";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  border: pxToRem(1) solid $color-gray200;
  border-radius: pxToRem(300);
  width: 100%;
  overflow: hidden;
  padding: $space-M 0;

  &.error {
    border-color: $color-red500;
  }
}

.inner {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  max-width: 70%;
  max-width: calc(100% - pxToRem(60));
}

.prefix {
  position: absolute;
  top: pxToRem(10);
  left: 0;
  transform: translate(-120%, 0);
  font-family: $font-centra;
  font-weight: $font-weight-light;
  font-size: $font-size-24;
  line-height: $line-height-24;
  color: $color-gray700;
}

.inputWrapper {
  position: relative;
  max-width: 100%;
}

.placeFiller,
.input {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  min-width: pxToRem(100);
  max-width: 100%;
  width: 100%;
  overflow: hidden;
}

.placeFiller {
  position: static;
  display: inline-block;
  opacity: 0;
  z-index: -1000;
  pointer-events: none;
  padding: 0 $space-XS 0 0;
  font-family: $font-centra;
  font-weight: $font-weight-book;
  font-size: $font-size-48;
  color: $color-gray700;
}

.rawInput {
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  background-color: transparent;
  width: 100%;
  font-family: $font-centra;
  font-weight: $font-weight-book;
  font-size: $font-size-48;
  color: $color-gray700;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: $color-gray200;
  }
}
