@import "@wingspanhq/fe-component-library/dist/theme.scss";

.bulletedText {
  padding-left: pxToRem(17);

  li {
    margin-bottom: $space-M;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    list-style-type: disc;
  }
}
