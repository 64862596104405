@import "@wingspanhq/fe-component-library/dist/theme.scss";

.container {
  position: relative;
}

.header {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
}

.main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: padding-left linear 0.25s;
}

@include breakpoint-min($breakpoint-S) {
  .container[data-navigationisopen="true"] {
    .main {
      padding-left: pxToRem(280);
    }
  }

  .container[data-navigationisopen="false"] {
    .main {
      padding-left: $space-4XL;
    }
  }
}

.content {
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;

  @include breakpoint-min($breakpoint-S) {
    margin-top: auto;
    display: flex;
    justify-content: center;
    padding: $space-6XL $space-3XL $space-XL $space-3XL;
  }
}

.linkBtnText {
  font-size: $font-size-12 !important;
}

.bodyOverflowHidden {
  overflow: hidden;
}
