@import "@wingspanhq/fe-component-library/dist/theme";
@import "index.module";

.card {
  @extend .cardBasic;

  &.virtualCard {
    background-image: url("../../../../assets/images/wallet/virtualCardBackground.svg");
  }

  &.physicalCard {
    background-image: url("../../../../assets/images/wallet/physicalCardBackground.svg");
  }

  .visibilityToggle {
    position: absolute;
    bottom: $space-M;
    left: $space-M;
    z-index: 1;

    &:focus {
      outline: 0;
      background-color: transparent;
    }
  }

  .iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .physicalCardMask {
    font-family: monospace;
    position: absolute;
    bottom: $space-XL;
    left: $space-XL;
  }


  &.virtualCard {
    // copy this file into embed-card.css after update
    // start:


    body {
      margin: 0;
      padding: 0;
      font-family: monospace;
      background-color: transparent;
    }

    body [id="pan"], body [id="month"], body [id="year"], body [id="cvv"] {
      cursor: pointer;
    }

    body [id="pan"]::after {
      content: "";
      width: 16px;
      height: 16px;
      background-size: cover;
      margin-left: 6px;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.33301 5.50806H2.66634C1.93101 5.50806 1.33301 6.10606 1.33301 6.84139V13.5081C1.33301 14.2434 1.93101 14.8414 2.66634 14.8414H9.33301C10.0683 14.8414 10.6663 14.2434 10.6663 13.5081V6.84139C10.6663 6.10606 10.0683 5.50806 9.33301 5.50806Z' fill='white'/%3E%3Cpath d='M13.333 1.50806H6.66634C5.93034 1.50806 5.33301 2.10539 5.33301 2.84139V4.17472H5.99967H10.6663C11.4023 4.17472 11.9997 4.77206 11.9997 5.50806V10.1747V10.8414H13.333C14.069 10.8414 14.6663 10.2441 14.6663 9.50806V2.84139C14.6663 2.10539 14.069 1.50806 13.333 1.50806Z' fill='white'/%3E%3C/svg%3E");
    }

    [id="pan"], [id="expiry"], [id="cvv"] {
      position: absolute;
      color: white;
      font-family: monospace;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
    }


    [id="card"] {
      color: white;
      position: relative;
      user-select: none;
      width: 99%;
      height: 99%;
    }

    [id="pan"] span {
      margin: 6px;
    }

    [id="pan"] {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      left: 22px;
      top: 86px;
    }

    [id="expiry"] {
      display: flex;
      flex-direction: row;
      gap: 4px;
      justify-content: center;

      left: 22px;
      top: 142px;
    }

    [id="cvv"] {
      top: 142px;
      left: 104px;
    }

    [id="cvv"]:hover, [id="pan"]:hover, [id="month"]:hover, [id="year"]:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }

    [id="cvv"]:active, [id="pan"]:active, [id="month"]:active, [id="year"]:active {
      background-color: rgba(0, 0, 0, 0.05);
    }

    [id="alert"] {
      display: none;
    }

    [id="alert"][class="success"] {
      display: block !important;
      color: transparent
    }

    [id="alert"][class="success"]::after {
      display: block;
      content: "Copied!";
      color: white;
      position: absolute;
      top: 80%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    // end
  }
}